import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useRef } from 'react';
import { string, shape } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import Button from '@magalu/stereo-ui/atoms/Button';
import { useOnClickOutside } from '@magalu/stereo-ui';
import { routePush } from '@magalu/mixer-utils';
import { Content, Menu, ButtonContainer, StyledLink } from './LogInMenu.styles';
function LogInMenu(_ref) {
  var data = _ref.data;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showMenu = _useState2[0],
    setShowMenu = _useState2[1];
  var menuRef = useRef();
  useOnClickOutside(menuRef, function () {
    setShowMenu(false);
  });
  return React.createElement(Content, null, React.createElement("strong", null, "Bem vindo :)"), React.createElement(StyledLink, {
    onClick: function onClick() {
      return setShowMenu(!showMenu);
    },
    "data-testid": "loginmenu-openmenu-button"
  }, "Entre ou cadastre-se", React.createElement(Icon, {
    color: "#fff",
    name: "ChevronDown"
  })), React.createElement(Menu, {
    display: showMenu,
    ref: menuRef,
    "data-testid": "loginmenu-menu"
  }, React.createElement(ButtonContainer, null, React.createElement(Button, {
    variation: "primary",
    size: "medium",
    uppercase: false,
    onClick: function onClick() {
      return routePush({
        path: data.nationalUrl
      });
    },
    "data-testid": "loginmenu-client-button"
  }, "Entrar como cliente"), React.createElement(Button, {
    variation: "outline",
    size: "medium",
    uppercase: false,
    onClick: function onClick() {
      return routePush({
        path: data.promoterUrl
      });
    },
    "data-testid": "loginmenu-promoter-button"
  }, "Entrar como influenciador"), React.createElement("hr", null)), React.createElement("a", {
    href: data.nationalUrl
  }, "ou cadastre-se")));
}
process.env.NODE_ENV !== "production" ? LogInMenu.propTypes = {
  data: shape({
    mvcClientUrl: string,
    nationalUrl: string,
    packageUrl: string,
    promoterUrl: string
  }).isRequired
} : void 0;
LogInMenu.defaultProps = {};
export default LogInMenu;