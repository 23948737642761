import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import LinkStereo from '@magalu/stereo-ui/atoms/Link';
import TextStereo from '@magalu/stereo-ui/atoms/Text';
import { getTextColor } from '@magalu/stereo-ui-styles';
export var Button = styled(TextStereo).attrs(function () {
  return {
    bg: 'transparent',
    fontSize: [0, 2, 2],
    fontWeight: 'bold',
    forwardedAs: 'button'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: none;\n  color: ", ";\n  cursor: pointer;\n"])), function (props) {
  return getTextColor('lightest')(props);
});
export var Link = styled(LinkStereo).attrs(function () {
  return {
    color: 'yellow',
    fontSize: [0, 2, 2],
    fontWeight: 'bold'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var Text = styled(TextStereo).attrs(function () {
  return {
    color: 'lightest',
    fontSize: [0, 2, 2],
    fontWeight: 'bold'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));