import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect, useRef } from 'react';
import { bool, string, shape } from 'prop-types';
import Button from '@magalu/stereo-ui/atoms/Button';
import MenuItem from '@magalu/stereo-ui/atoms/MenuItem';
import { useOnClickOutside } from '@magalu/stereo-ui';
import { getCookie } from '@magalu/mixer-utils';
import { Container, Loggout, Menu } from './LoggedMenu.styles';
function LoggedMenu(_ref) {
  var data = _ref.data,
    isPromoter = _ref.isPromoter,
    userName = _ref.userName;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showMenuButton = _useState2[0],
    setShowMenuButton = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    showMenu = _useState4[0],
    setShowMenu = _useState4[1];
  var _useState5 = useState([]),
    _useState6 = _slicedToArray(_useState5, 2),
    menuList = _useState6[0],
    setMenuList = _useState6[1];
  var sidebarHeadingCookie = getCookie('toggle_sidebarheading_pmd');
  var toggleLogin = sidebarHeadingCookie === 'true';
  useEffect(function () {
    var items = data.menuItems.filter(function (item) {
      return (isPromoter && data.promoterMenu || data.clientMenu).includes(item.id);
    });
    setMenuList(items);
  }, [isPromoter]);
  var menuRef = useRef();
  useOnClickOutside(menuRef, function () {
    setShowMenu(false);
  });
  useEffect(function () {
    setShowMenuButton(true);
  }, []);
  return React.createElement(Container, null, showMenuButton && React.createElement(Button, {
    variation: "outline",
    customColor: {
      "default": '#fff',
      hover: '#fff'
    },
    size: "medium",
    endIcon: "ChevronDown",
    centered: true,
    uppercase: false,
    onClick: function onClick() {
      return setShowMenu(!showMenu);
    },
    "data-testid": "loggedmenu-openmenu-button"
  }, React.createElement("p", null, "Seu espa\xE7o")), React.createElement(Menu, {
    display: showMenu,
    ref: menuRef,
    "data-testid": "loggedmenu-menu"
  }, menuList.map(function (item) {
    return React.createElement(MenuItem, {
      key: item.id,
      icon: item.icon,
      text: item.text,
      chevronRight: false,
      as: "a",
      href: toggleLogin && item.newHref ? item.newHref : item.href,
      "data-testid": "loggedmenu-menu".concat(item.id, "-button")
    });
  }), React.createElement(Loggout, {
    href: data.logoutUrl
  }, userName && "N\xE3o \xE9 ".concat(userName.split(' ')[0], "? "), React.createElement("span", null, "Sair"))));
}
process.env.NODE_ENV !== "production" ? LoggedMenu.propTypes = {
  data: shape({
    clientMenu: shape([]),
    logoutUrl: string,
    menuItem: shape([]),
    promoterMenu: shape([])
  }).isRequired,
  isPromoter: bool,
  userName: string
} : void 0;
LoggedMenu.defaultProps = {
  isPromoter: false,
  userName: ''
};
export default LoggedMenu;