import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["path", "pathSufix", "toggleAutoShipping", "label", "publish", "spa", "refresh"];
import React from 'react';
import { shape, string } from 'prop-types';
import StereoLink from '@magalu/stereo-ui/atoms/Link';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { usePublisher } from '@magalu/mixer-publisher';
import { routePush } from '@magalu/mixer-utils';
var Link = function Link(_ref) {
  var StaticProps = _ref["static"];
  var path = StaticProps.path,
    _StaticProps$pathSufi = StaticProps.pathSufix,
    pathSufix = _StaticProps$pathSufi === void 0 ? '' : _StaticProps$pathSufi,
    toggleAutoShipping = StaticProps.toggleAutoShipping,
    label = StaticProps.label,
    event = StaticProps.publish,
    spa = StaticProps.spa,
    refresh = StaticProps.refresh,
    props = _objectWithoutProperties(StaticProps, _excluded);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var completePath = "".concat(path).concat(pathSufix);
  var handleOnClick = function handleOnClick(e) {
    e.preventDefault();
    if (event) publish(event);
    routePush({
      path: completePath,
      refresh: refresh,
      spa: spa
    });
  };
  return React.createElement(StereoLink, _extends({
    "data-testid": "link",
    href: completePath,
    onClick: handleOnClick,
    display: "inline-block"
  }, props), label);
};
process.env.NODE_ENV !== "production" ? Link.propTypes = {
  "static": shape({
    url: string
  }),
  structure: shape({
    config: shape({
      domain: string
    })
  })
} : void 0;
Link.defaultProps = {
  "static": {},
  structure: {
    config: {}
  }
};
Link.ssr = true;
Link.displayName = 'Link';
export default withLayoutProps(Link);