import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import { themeGet, getPaletteColor, getMediaQuery } from '@magalu/stereo-ui-styles';
export var Alert = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: none;\n  position: absolute;\n  top: 60px;\n  z-index: 2;\n  background-color: ", ";\n  border-radius: 5px;\n  font-size: ", "px;\n  line-height: 40px;\n  color: #999;\n  text-align: center;\n  word-break: keep-all;\n  padding: 0 15px;\n  white-space: nowrap;\n  left: 50%;\n  transform: translate(-50%);\n\n  & span {\n    color: ", ";\n  }\n\n  &:after {\n    content: '';\n    display: inline-block;\n    width: 0;\n    height: 0;\n    border-left: 6px solid transparent;\n    border-right: 6px solid transparent;\n    border-bottom: 6px solid ", ";\n    position: absolute;\n    top: -6px;\n    left: 50%;\n    transform: translate(-50%);\n  }\n"])), themeGet('colors.white'), themeGet('fontSizes.1'), getPaletteColor('pmd.base'), themeGet('colors.white'));
export var Background = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n\n  & div {\n    position: absolute;\n    width: 40px;\n    height: 100%;\n    left: 50%;\n    transform: translate(-50%);\n    background-color: ", ";\n    z-index: 0;\n\n    &:after {\n      content: '';\n      display: inline-block;\n      width: 0;\n      height: 0;\n      border-left: 20px solid ", ";\n      border-right: 20px solid ", ";\n      border-top: 14px solid ", ";\n      position: absolute;\n      bottom: 0;\n    }\n  }\n\n  & hr {\n    top: -2px;\n    position: absolute;\n    width: 100%;\n    z-index: 1;\n    border-radius: 50%;\n    border: 2px solid transparent;\n    border-bottom: 3px solid #33333355;\n  }\n"])), getPaletteColor('background.loyalty'), getPaletteColor('pmd.base'), getPaletteColor('pmd.base'), getPaletteColor('background.loyalty'));
export var Badge = styled.img(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 6px;\n  left: 50%;\n  transform: translate(-50%);\n  z-index: 2;\n"])));
export var Container = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: none;\n  flex: 0 0 auto;\n  width: ", ";\n  height: ", ";\n  position: relative;\n  margin-right: ", ";\n\n  &:focus ", " {\n    display: block;\n  }\n\n  &:hover ", " {\n    display: block;\n  }\n\n  ", " {\n    display: block;\n  }\n"])), function (_ref) {
  var isInfluencer = _ref.isInfluencer;
  return isInfluencer ? '42px' : '79px';
}, function (_ref2) {
  var isInfluencer = _ref2.isInfluencer;
  return isInfluencer ? '60px' : '51px';
}, function (_ref3) {
  var isInfluencer = _ref3.isInfluencer;
  return isInfluencer ? '35px' : '22px';
}, Alert, Alert, getMediaQuery('medium'));