import React from 'react';
import { bool, shape } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import { Container, Label } from './MinimumOrderQuantity.styles';
var MinimumOrderQuantity = function MinimumOrderQuantity(_ref) {
  var _data$product;
  var data = _ref.data,
    staticProps = _ref["static"];
  var mobile = staticProps == null ? void 0 : staticProps.isMobile;
  var minimumQuantity = data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.minimumOrderQuantity;
  return React.createElement(React.Fragment, null, React.createElement(Container, {
    "data-testid": "product-minimum-container",
    isMobile: mobile
  }, React.createElement(Box, {
    width: ['100%', '100%', '65%']
  }, React.createElement(Label, {
    isMobile: mobile,
    "data-testid": "product-minimum"
  }, "Quantidade m\xEDnima: ".concat(minimumQuantity, " unidades")))));
};
process.env.NODE_ENV !== "production" ? MinimumOrderQuantity.propTypes = {
  data: shape({}),
  "static": shape({
    isMobile: bool
  })
} : void 0;
MinimumOrderQuantity.defaultProps = {
  data: {},
  "static": shape({
    isMobile: false
  })
};
MinimumOrderQuantity.ssr = true;
export default MinimumOrderQuantity;