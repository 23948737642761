export var parseAdsProduct = function parseAdsProduct(adsResponse) {
  return adsResponse == null ? void 0 : adsResponse.map(function (ads, index) {
    if (!ads) return null;
    return {
      adId: ads.adId,
      ads: {
        campaignId: ads.campaignId,
        id: ads.adId,
        sponsored: false
      },
      available: true,
      brand: {
        label: ads.brand
      },
      category: {
        name: ads.category
      },
      image: ads.imageUrl,
      path: '/',
      position: index + 1,
      price: {
        bestPrice: ads.publisherCustomFields.prices[0].best_price.total_amount,
        price: ads.publisherCustomFields.prices[0].price
      },
      rating: {
        count: Number(ads.reviewCount),
        score: Number(ads.reviewRating)
      },
      seller: {
        id: ads.sellerId
      },
      subcategory: {
        name: ads.subCategory
      },
      title: ads.name,
      url: ads.url
    };
  });
};