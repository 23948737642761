import slugfy from 'slugify';
export var parseSearchToAds = function parseSearchToAds(search) {
  if (!search) return undefined;
  var productsWithoutAds = [];
  for (var i = 0; i < search.length; i += 1) {
    var product = search[i];
    if (!product.ads) {
      var _product$brand, _product$category, _product$subcategory;
      productsWithoutAds.push({
        brand: slugfy((product == null ? void 0 : (_product$brand = product.brand) == null ? void 0 : _product$brand.label) || '').toLocaleLowerCase(),
        category: slugfy((product == null ? void 0 : (_product$category = product.category) == null ? void 0 : _product$category.name) || '').toLocaleLowerCase(),
        name: product.title,
        sku: product.id,
        subCategory: slugfy((product == null ? void 0 : (_product$subcategory = product.subcategory) == null ? void 0 : _product$subcategory.name) || '').toLocaleLowerCase()
      });
    }
    if (productsWithoutAds.length === 3) break;
  }
  return productsWithoutAds;
};