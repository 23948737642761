var useAdsSdk = function useAdsSdk(context, adsResponse) {
  var _window;
  if ((_window = window) == null ? void 0 : _window.magaluAdsEvents) {
    var _window2, _window2$magaluAdsEve;
    (_window2 = window) == null ? void 0 : (_window2$magaluAdsEve = _window2.magaluAdsEvents) == null ? void 0 : _window2$magaluAdsEve.init(context, adsResponse);
  } else {
    document.addEventListener('magalu.ads.events.ready', function () {
      window.magaluAdsEvents.init(context, adsResponse);
    });
  }
};
export default useAdsSdk;