import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect, useMemo } from 'react';
import { bool, shape, string } from 'prop-types';
import HeaderModule from '@magalu/stereo-ui/templates/Header';
import { useSuggestions, useTopTerms } from '@magalu/mixer-graphql';
import { adaptUseTopTermsToStereoHeaderProps, routePush, slug } from '@magalu/mixer-utils';
import { termToUrl } from '@magalu/mixer-structure';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { parseHeaderLinksSuggestions } from '../../../commons';
import renderCustomSidebar from './renderCustomSidebar';
import parseHeaderData from './parseHeaderData';
function Header(_ref) {
  var _headerData$logo, _structure$route3;
  var data = _ref.data,
    staticProps = _ref["static"],
    structure = _ref.structure;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var headerData = staticProps.headerData,
    searchUrl = staticProps.searchUrl,
    customSidebar = staticProps.customSidebar,
    relativePathRedirect = staticProps.relativePathRedirect;
  var logoPrefix = headerData == null ? void 0 : (_headerData$logo = headerData.logo) == null ? void 0 : _headerData$logo.prefix;
  var _ref2 = structure.route || {},
    _ref2$term = _ref2.term,
    initialQuery = _ref2$term === void 0 ? '' : _ref2$term,
    storeName = _ref2.storeId;
  var _ref3 = structure.cookies || {},
    partnerId = _ref3.partnerId;
  var _useSuggestions = useSuggestions({
      partnerId: partnerId
    }),
    suggestions = _useSuggestions.suggestions,
    getSuggestions = _useSuggestions.getSuggestions,
    cleanSuggestions = _useSuggestions.cleanSuggestions;
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    chosenSuggestion = _useState2[0],
    setChosenSuggestion = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    showTopTerms = _useState4[0],
    setShowTopTerms = _useState4[1];
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    isRendered = _useState6[0],
    setIsRendered = _useState6[1];
  var _useTopTerms = useTopTerms(),
    _useTopTerms$topTerms = _useTopTerms.topTerms,
    _useTopTerms$topTerms2 = _useTopTerms$topTerms.label,
    topTermsLabel = _useTopTerms$topTerms2 === void 0 ? '' : _useTopTerms$topTerms2,
    _useTopTerms$topTerms3 = _useTopTerms$topTerms.terms,
    topTermsList = _useTopTerms$topTerms3 === void 0 ? [] : _useTopTerms$topTerms3;
  var topTermsProps = adaptUseTopTermsToStereoHeaderProps({
    showTopTerms: showTopTerms,
    topTermsLabel: topTermsLabel,
    topTermsList: topTermsList
  });
  var _useState7 = useState(initialQuery),
    _useState8 = _slicedToArray(_useState7, 2),
    term = _useState8[0],
    setTerm = _useState8[1];
  var parsedData = parseHeaderData({
    data: data,
    staticProps: staticProps,
    structure: structure
  });
  var logoProps = staticProps.logoProps || {
    prefix: logoPrefix,
    storeName: storeName
  };
  var publishSuggestionFromRoute = function publishSuggestionFromRoute(suggestion) {
    var position = suggestions.findIndex(function (item) {
      return item.term === suggestion;
    }) + 1;
    publish('suggestion:click', {
      position: position,
      term: suggestion
    });
  };
  useEffect(function () {
    var _structure$route;
    if (chosenSuggestion && chosenSuggestion.includes(structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.term)) {
      var _structure$route2;
      publishSuggestionFromRoute(structure == null ? void 0 : (_structure$route2 = structure.route) == null ? void 0 : _structure$route2.term);
    }
  }, [structure == null ? void 0 : (_structure$route3 = structure.route) == null ? void 0 : _structure$route3.term, chosenSuggestion]);
  useEffect(function () {
    setTimeout(function () {
      setIsRendered(true);
    }, 1000);
  }, []);
  var suggestionLinksProps = useMemo(function () {
    return structure.id === 'home' ? parseHeaderLinksSuggestions(staticProps == null ? void 0 : staticProps.linksSuggestionsData) : {};
  }, [structure.id]);
  useEffect(function () {
    if (suggestionLinksProps && structure.id === 'home') {
      publish('link-suggestions:impression', suggestionLinksProps);
    }
  }, [suggestionLinksProps]);
  var onLogoClick = function onLogoClick(e) {
    e.preventDefault();
    routePush({
      path: headerData.logo.url,
      spa: true
    });
  };
  var onSearch = function onSearch(e, newTerm, from) {
    e.preventDefault();
    var newTermTrim = newTerm.trim();
    if (e.type === 'submit' && newTermTrim) {
      publish('search:submit', {
        term: newTermTrim
      });
    }
    var termStr = newTermTrim ? "".concat(termToUrl(slug(newTermTrim)), "/") : '';
    var newTermUrl = "".concat(searchUrl).concat(searchUrl.endsWith('/') ? '' : '/').concat(termStr);
    var termUrl = from ? "".concat(newTermUrl, "?from=").concat(from) : "".concat(newTermUrl);
    routePush({
      path: termUrl,
      spa: true
    });
  };
  var handleSuggest = function handleSuggest(suggestionsTerm) {
    getSuggestions({
      term: suggestionsTerm
    });
  };
  var handleEventMenuClick = function handleEventMenuClick(label, positionLabel) {
    publish('menu:click', {
      label: label,
      positionLabel: positionLabel
    });
  };
  var onShoppingBagClick = function onShoppingBagClick() {
    publish('shoppingbag:click');
  };
  var handleSuggestionClick = function handleSuggestionClick(suggestion) {
    setChosenSuggestion(suggestion);
  };
  var handleTopTermItemClick = function handleTopTermItemClick(topTerm) {
    setShowTopTerms(false);
    var position = topTerm.index,
      clickedTopTerm = topTerm.term;
    publish('suggestion:topterm:click', {
      position: position,
      term: clickedTopTerm
    });
  };
  var onOpenInput = function onOpenInput() {
    if (term.trim()) {
      setShowTopTerms(false);
      if (suggestions.length === 0) {
        handleSuggest(term);
      }
    } else {
      cleanSuggestions();
      setShowTopTerms(true);
    }
  };
  var onCloseInput = function onCloseInput() {
    setShowTopTerms(false);
    publish('search:close', {
      term: term
    });
  };
  var onClearInput = function onClearInput() {
    publish('search:clear', {
      term: term
    });
    cleanSuggestions();
    setShowTopTerms(true);
  };
  var handleTermTypeChange = function handleTermTypeChange(newTerm) {
    if (newTerm.length === 0) {
      cleanSuggestions();
      setShowTopTerms(true);
    } else {
      setShowTopTerms(false);
    }
    setTerm(newTerm);
    handleSuggest(newTerm);
  };
  var handleLogout = function handleLogout() {
    handleEventMenuClick('sair');
  };
  var _useState9 = useState(false),
    _useState10 = _slicedToArray(_useState9, 2),
    openAccordion = _useState10[0],
    setOpenAccordion = _useState10[1];
  var handleCollapsedClick = function handleCollapsedClick() {
    setOpenAccordion(!openAccordion);
    handleEventMenuClick('ver-todos-os-departamentos');
  };
  var handleHeaderMenuClick = function handleHeaderMenuClick(e, item) {
    e.preventDefault();
    publish('menu:header:click', {
      label: item == null ? void 0 : item.label,
      structure: structure
    });
    routePush({
      path: item == null ? void 0 : item.url,
      spa: true
    });
  };
  var handleMenuItemClick = function handleMenuItemClick(e, item, positionLabel) {
    e.preventDefault();
    handleEventMenuClick(item == null ? void 0 : item.label, positionLabel);
    var url = relativePathRedirect && (item == null ? void 0 : item.path) ? item.path : item == null ? void 0 : item.url;
    routePush({
      path: url,
      spa: true
    });
  };
  var handleClickLinkSuggestion = function handleClickLinkSuggestion(e, link) {
    e.preventDefault();
    publish('link-suggestions:click', link);
    routePush({
      path: link == null ? void 0 : link.href,
      spa: true
    });
  };
  return React.createElement(HeaderModule, _extends({
    customSidebar: renderCustomSidebar(customSidebar, {
      data: parsedData,
      onCollapsedClick: handleCollapsedClick,
      onMenuHeaderClick: handleHeaderMenuClick,
      onMenuItemClick: handleMenuItemClick,
      storeName: storeName,
      structure: structure
    }),
    data: parsedData,
    initialValue: term,
    isRendered: isRendered,
    logoProps: logoProps,
    onCollapsedClick: handleCollapsedClick,
    onMenuItemClick: handleMenuItemClick,
    onMenuHeaderClick: handleHeaderMenuClick,
    onInputClear: onClearInput,
    onInputClose: onCloseInput,
    onInputOpen: onOpenInput,
    onLogoClick: onLogoClick,
    onLogout: handleLogout,
    onSearch: onSearch,
    onHeaderSuggestionLinkClick: handleClickLinkSuggestion,
    onShoppingBagClick: onShoppingBagClick,
    onSuggestItemClick: handleSuggestionClick,
    onTopTermItemClick: handleTopTermItemClick,
    onTypeChange: handleTermTypeChange,
    suggestData: suggestions
  }, topTermsProps, suggestionLinksProps));
}
process.env.NODE_ENV !== "production" ? Header.propTypes = {
  data: shape({}),
  "static": shape({
    headerData: shape({}),
    menuData: shape({}),
    relativePathRedirect: bool,
    searchUrl: string
  }),
  structure: shape({})
} : void 0;
Header.defaultProps = {
  data: {},
  "static": {
    headerData: {
      logo: {
        name: '',
        prefix: undefined,
        url: '/'
      }
    },
    menuData: {
      data: {
        header: {
          helpers: [],
          label: '',
          url: ''
        },
        moreInformation: []
      }
    },
    relativePathRedirect: false,
    searchUrl: '/busca/'
  },
  structure: {}
};
Header.dataSource = {
  categories: {
    query: 'AllCategoriesQuery'
  },
  channel: {
    query: 'ChannelQuery'
  }
};
Header.ssr = true;
Header.displayName = 'Header';
export default withLayoutProps(Header);