import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import { bool, string, shape } from 'prop-types';
import Dropdown from '@magalu/stereo-ui/atoms/Dropdown';
import Link from '@magalu/stereo-ui/atoms/Link';
import { Icon } from '@magalu/stereo-ui-icons';
import { Container } from './LoggedMenu.styles';
function LoggedMenu(_ref) {
  var data = _ref.data,
    isPromoter = _ref.isPromoter,
    userName = _ref.userName;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showMenu = _useState2[0],
    setShowMenu = _useState2[1];
  var _useState3 = useState([]),
    _useState4 = _slicedToArray(_useState3, 2),
    menuList = _useState4[0],
    setMenuList = _useState4[1];
  useEffect(function () {
    if (data.menuItems) {
      var items = data.menuItems.filter(function (item) {
        return (isPromoter && data.promoterMenu || data.clientMenu).includes(item.id);
      });
      setMenuList(items);
    }
  }, [isPromoter]);
  useEffect(function () {
    setShowMenu(true);
  }, []);
  var getIcon = function getIcon(icon) {
    return React.createElement(Icon, {
      name: icon,
      color: "#404040"
    });
  };
  return React.createElement(Container, null, showMenu && React.createElement(Dropdown, {
    mouseenter: true,
    pointing: true
  }, React.createElement(Dropdown.Title, {
    color: "text.white",
    fill: "background.lighter"
  }, React.createElement("span", null, "Ol\xE1,\xA0", React.createElement("b", null, userName && userName.split(' ')[0]))), React.createElement(Dropdown.Menu, {
    pointing: true,
    fontSize: 0,
    p: 2
  }, menuList.map(function (_ref2) {
    var icon = _ref2.icon,
      id = _ref2.id,
      label = _ref2.label,
      url = _ref2.url;
    return React.createElement(Dropdown.Item, {
      key: id,
      startIcon: getIcon(icon),
      p: 1,
      my: 2
    }, React.createElement(Link, {
      href: url,
      fontSize: 0
    }, label));
  }), React.createElement(Dropdown.Divider, null), React.createElement(Dropdown.Item, {
    p: 1,
    my: 2,
    disableHover: true
  }, userName && "N\xE3o \xE9 ".concat(userName.split(' ')[0], "? "), React.createElement(Link, {
    underline: true,
    px: 0,
    fontSize: 0,
    href: data.logoutUrl,
    "data-testid": "link-logout"
  }, "Sair")))));
}
process.env.NODE_ENV !== "production" ? LoggedMenu.propTypes = {
  data: shape({
    clientMenu: shape([]),
    logoutUrl: string,
    menuItem: shape([]),
    promoterMenu: shape([])
  }).isRequired,
  isPromoter: bool,
  userName: string
} : void 0;
LoggedMenu.defaultProps = {
  isPromoter: false,
  userName: ''
};
export default LoggedMenu;