import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Container from '@magalu/stereo-ui/molecules/MessageBox';
import { usePublisher } from '@magalu/mixer-publisher';
import { Button, Text, Link } from './MessageBox.styles';
var MessageBox = function MessageBox(_ref) {
  var staticProps = _ref["static"];
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    messageShow = _useState2[0],
    setMessageShow = _useState2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var hrefLink = staticProps.hrefLink,
    text = staticProps.text,
    textButton = staticProps.textButton,
    textLink = staticProps.textLink;
  var onClickButton = function onClickButton() {
    publish('addtoacceptpolity:success');
    setMessageShow(false);
  };
  return messageShow && React.createElement(Container, null, React.createElement(Flex, {
    justifyContent: "center",
    width: ['90%', '60%', '60%']
  }, React.createElement(Text, null, text, React.createElement(Link, {
    href: hrefLink,
    target: "_blank",
    rel: "noopener"
  }, textLink))), React.createElement(Flex, {
    alignItems: "center",
    justifyContent: ['flex-end', 'center', 'center'],
    mt: [1, 0, 0]
  }, React.createElement(Button, {
    "data-testid": "button-message-box",
    onClick: onClickButton
  }, textButton)));
};
process.env.NODE_ENV !== "production" ? MessageBox.propTypes = {
  "static": shape({
    hrefLink: string,
    text: string,
    textButton: string,
    textLink: string
  })
} : void 0;
MessageBox.defaultProps = {
  "static": {}
};
MessageBox.ssr = true;
export default MessageBox;