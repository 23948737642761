import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect, useRef } from 'react';
import { string, shape } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import MenuItem from '@magalu/stereo-ui/atoms/MenuItem';
import Button from '@magalu/stereo-ui/atoms/Button';
import { useOnClickOutside } from '@magalu/stereo-ui';
import { routePush, getCookie } from '@magalu/mixer-utils';
import { Container, UserImage, Content, Menu, ButtonContainer, NewButtonContainer, PackagesContainer, StyledLink } from './LogInMenu.styles';
function LogInMenu(_ref) {
  var data = _ref.data,
    theme = _ref.theme;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showMenu = _useState2[0],
    setShowMenu = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    toggleLogin = _useState4[0],
    setToggleLogin = _useState4[1];
  useEffect(function () {
    var sidebarHeadingCookie = getCookie('toggle_sidebarheading_pmd');
    setToggleLogin(sidebarHeadingCookie === 'true');
  }, [toggleLogin]);
  var menuRef = useRef();
  useOnClickOutside(menuRef, function () {
    setShowMenu(false);
  });
  return React.createElement(Container, null, React.createElement(UserImage, {
    src: "https://mvc.mlcdn.com.br/magazinevoce/img/common/person.svg?1654521562"
  }), React.createElement(Content, null, React.createElement("strong", null, "Bem vindo :)"), React.createElement(StyledLink, {
    onClick: function onClick() {
      return setShowMenu(!showMenu);
    },
    "data-testid": "loginmenu-openmenu-button"
  }, "Entre ou cadastre-se", React.createElement(Icon, {
    color: "#fff",
    name: "ChevronDown"
  })), React.createElement(Menu, {
    display: showMenu,
    ref: menuRef,
    "data-testid": "loginmenu-menu"
  }, toggleLogin ? React.createElement(NewButtonContainer, null, React.createElement(Button, {
    variation: "primary",
    customColor: {
      "default": theme == null ? void 0 : theme.palette.pmd.light,
      hover: theme == null ? void 0 : theme.palette.pmd.light
    },
    size: "medium",
    uppercase: false,
    onClick: function onClick() {
      return routePush({
        path: data.nationalUrl
      });
    },
    "data-testid": "loginmenu-client-button"
  }, "Entrar como cliente"), React.createElement(Button, {
    variation: "outline",
    customColor: {
      "default": theme == null ? void 0 : theme.palette.pmd.light,
      hover: theme == null ? void 0 : theme.palette.pmd.light
    },
    size: "medium",
    uppercase: false,
    onClick: function onClick() {
      return routePush({
        path: data.promoterUrl
      });
    },
    "data-testid": "loginmenu-promoter-button"
  }, "Entrar como influenciador"), React.createElement("hr", null)) : React.createElement(ButtonContainer, null, React.createElement(Button, {
    variation: "primary",
    customColor: {
      "default": theme == null ? void 0 : theme.palette.pmd.light,
      hover: theme == null ? void 0 : theme.palette.pmd.light
    },
    size: "medium",
    uppercase: false,
    onClick: function onClick() {
      return routePush({
        path: data.mvcClientUrl
      });
    },
    "data-testid": "loginmenu-enter-button"
  }, "Entrar")), React.createElement("a", {
    href: toggleLogin ? data.nationalUrl : data.mvcClientUrl
  }, "ou cadastre-se"), !toggleLogin && React.createElement(PackagesContainer, null, React.createElement(MenuItem, {
    icon: "Package",
    text: "Meus pedidos",
    chevronRight: false,
    noBorder: true,
    onClick: function onClick() {
      return routePush({
        path: data.packageUrl
      });
    },
    "data-testid": "loginmenu-package-button"
  })))));
}
process.env.NODE_ENV !== "production" ? LogInMenu.propTypes = {
  data: shape({
    mvcClientUrl: string,
    nationalUrl: string,
    packageUrl: string,
    promoterUrl: string
  }).isRequired,
  theme: shape({}).isRequired
} : void 0;
LogInMenu.defaultProps = {};
export default LogInMenu;