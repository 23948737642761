import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { AllDepartments, Container, MainCategory, MainMenu, SubCategory, SubMenu } from './Categories.styles';
function Categories(_ref) {
  var highlighted = _ref.highlighted,
    allCategories = _ref.allCategories,
    storeName = _ref.storeName;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showSubMenu = _useState2[0],
    setShowSubMenu = _useState2[1];
  var lowerStoreName = (storeName || '').toLowerCase();
  return React.createElement(Container, null, React.createElement(MainMenu, null, React.createElement("li", null, React.createElement(AllDepartments, {
    onMouseEnter: function onMouseEnter() {
      return setShowSubMenu(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setShowSubMenu(false);
    },
    onClick: function onClick() {
      return setShowSubMenu(function (previousState) {
        return !previousState;
      });
    },
    "aria-expanded": showSubMenu,
    "data-testid": "categories-alldepartments"
  }, React.createElement(Icon, {
    name: "Burger"
  }), React.createElement("span", null, "Todos os departamentos"), React.createElement(Icon, {
    name: "ChevronDown"
  }))), highlighted.map(function (highlight) {
    return React.createElement("li", {
      key: highlight.id
    }, React.createElement(MainCategory, {
      href: "/magazine".concat(lowerStoreName, "/").concat(highlight.slug, "/l/").concat(highlight.id_ml.toLowerCase())
    }, highlight.name));
  })), React.createElement(SubMenu, {
    display: showSubMenu,
    "data-testid": "categories-submenu"
  }, allCategories.map(function (category) {
    return React.createElement("li", {
      key: category.id
    }, React.createElement(SubCategory, {
      href: category.meta.canonical
    }, category.description));
  })));
}
process.env.NODE_ENV !== "production" ? Categories.propTypes = {
  allCategories: arrayOf(shape({})),
  highlighted: arrayOf(shape({})),
  storeName: string.isRequired
} : void 0;
Categories.defaultProps = {
  allCategories: [],
  highlighted: []
};
export default Categories;