import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import Link from '@magalu/stereo-ui/atoms/Link';
import { themeGet, getPaletteColor, getTextColor, getBackgroundColor, getFontSize } from '@magalu/stereo-ui-styles';
export var ButtonContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  & button {\n    width: 200px;\n    margin-bottom: 8px;\n    & > label {\n      font-weight: ", ";\n    }\n  }\n\n  & hr {\n    border: 0;\n    border-top: ", ";\n    margin: 10px 0 5px 0;\n  }\n"])), themeGet('fontWeight.regular'), themeGet('borders.0'));
export var Content = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-right: 30px;\n  font-size: ", "px;\n  color: ", ";\n  line-height: 20px;\n\n  & > button {\n    padding: 0;\n    display: flex;\n    text-wrap: nowrap;\n  }\n"])), getFontSize(1), getTextColor('lightest'));
export var Menu = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: ", ";\n  position: absolute;\n  background-color: ", ";\n  border: ", ";\n  border-radius: ", ";\n  margin-top: 2px;\n  padding: 10px;\n  padding-bottom: 0;\n  z-index: 1;\n\n  & a {\n    cursor: pointer;\n    text-align: center;\n    font-size: ", "px;\n    color: ", ";\n    background: ", ";\n    text-decoration: none;\n    width: 100%;\n    display: inline-block;\n    padding-bottom: 10px;\n\n    &:hover {\n      color: ", ";\n    }\n  }\n"])), function (_ref) {
  var display = _ref.display;
  return display ? 'block' : 'none';
}, getBackgroundColor('lighter'), themeGet('borders.0'), themeGet('radii.default'), getFontSize(1), getTextColor('light'), getBackgroundColor('lighter'), getPaletteColor('magalu.base'));
export var StyledLink = styled(Link).attrs(function (props) {
  return _extends({
    color: '#fff'
  }, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  text-align: left;\n"])));