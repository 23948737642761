import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import { shape, string, oneOf } from 'prop-types';
import ShoppingBagButton from '@magalu/stereo-ui/molecules/ShoppingBagButton';
import SearchInput from '@magalu/stereo-ui/molecules/SearchInput';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { useSuggestions } from '@magalu/mixer-graphql';
import { routePush, slug } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import { termToUrl } from '@magalu/mixer-structure';
import Profile from './components/Profile';
import Loyalty from './components/Loyalty';
import Categories from './components/Categories';
import LoggedMenu from './components/LoggedMenu';
import LogInMenu from './components/LogInMenu';
import { Header, Main, Search, Space, Cart, CategoriesContainer } from './HeaderDesktopPMD.styles';
function HeaderDesktopPMD(_ref) {
  var _staticProps$menuData, _staticProps$menuData2, _staticProps$menuData3, _structure$route4;
  var data = _ref.data,
    staticProps = _ref["static"],
    structure = _ref.structure;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var allCategories = data.allCategories;
  var cookies = structure.cookies,
    _structure$route = structure.route,
    route = _structure$route === void 0 ? {} : _structure$route;
  var _staticProps$loggedMe = staticProps.loggedMenu,
    loggedMenu = _staticProps$loggedMe === void 0 ? {} : _staticProps$loggedMe,
    _staticProps$logInMen = staticProps.logInMenu,
    logInMenu = _staticProps$logInMen === void 0 ? {} : _staticProps$logInMen,
    _staticProps$store = staticProps.store,
    store = _staticProps$store === void 0 ? {} : _staticProps$store,
    _staticProps$loyalty = staticProps.loyalty,
    loyalty = _staticProps$loyalty === void 0 ? {} : _staticProps$loyalty;
  var userData = cookies.promoterData || cookies.accountData;
  var isLogged = userData || false;
  var isPromoter = isLogged && structure.cookies.promoterData;
  var highlighted = staticProps == null ? void 0 : (_staticProps$menuData = staticProps.menuData) == null ? void 0 : (_staticProps$menuData2 = _staticProps$menuData.data) == null ? void 0 : (_staticProps$menuData3 = _staticProps$menuData2.categoryList) == null ? void 0 : _staticProps$menuData3.highlighted;
  var partnerId = cookies.partnerId;
  var _useSuggestions = useSuggestions({
      partnerId: partnerId
    }),
    suggestions = _useSuggestions.suggestions,
    getSuggestions = _useSuggestions.getSuggestions;
  var _route$term = route.term,
    initialQuery = _route$term === void 0 ? '' : _route$term;
  var _useState = useState(initialQuery),
    _useState2 = _slicedToArray(_useState, 2),
    term = _useState2[0],
    setTerm = _useState2[1];
  var _useState3 = useState(),
    _useState4 = _slicedToArray(_useState3, 2),
    chosenSuggestion = _useState4[0],
    setChosenSuggestion = _useState4[1];
  var publishSuggestionFromRoute = function publishSuggestionFromRoute(suggestion) {
    var position = suggestions.findIndex(function (item) {
      return item.term === suggestion;
    }) + 1;
    publish('suggestion:click', {
      position: position,
      term: suggestion
    });
  };
  useEffect(function () {
    var _structure$route2;
    if (chosenSuggestion && (chosenSuggestion == null ? void 0 : chosenSuggestion.includes(structure == null ? void 0 : (_structure$route2 = structure.route) == null ? void 0 : _structure$route2.term))) {
      var _structure$route3;
      publishSuggestionFromRoute(structure == null ? void 0 : (_structure$route3 = structure.route) == null ? void 0 : _structure$route3.term);
    }
  }, [structure == null ? void 0 : (_structure$route4 = structure.route) == null ? void 0 : _structure$route4.term, chosenSuggestion]);
  var onSearch = function onSearch(e, newTerm) {
    e.preventDefault();
    var newTermTrim = newTerm.trim();
    if (e.type === 'submit' && newTermTrim) {
      publish('search:submit', {
        term: newTermTrim
      });
    }
    var termStr = newTermTrim ? "".concat(termToUrl(slug(newTermTrim)), "/") : '';
    var newTermUrl = "".concat(staticProps.searchUrl).concat(termStr);
    routePush({
      path: newTermUrl,
      spa: true
    });
  };
  var handleSuggest = function handleSuggest(suggestionsTerm) {
    getSuggestions({
      term: suggestionsTerm
    });
  };
  var handleSuggestionClick = function handleSuggestionClick(suggestion) {
    setChosenSuggestion(suggestion);
  };
  var onOpenInput = function onOpenInput() {
    if (term.trim() && !suggestions.length) {
      handleSuggest(term);
    }
  };
  var handleTermTypeChange = function handleTermTypeChange(newTerm) {
    setTerm(newTerm);
    handleSuggest(newTerm);
  };
  return React.createElement(Header, null, React.createElement(Main, null, React.createElement(Profile, {
    store: store
  }), React.createElement(Loyalty, {
    loyalty: loyalty,
    isInfluencer: loyalty == null ? void 0 : loyalty.isInfluencer
  }), React.createElement(Search, null, React.createElement(SearchInput, {
    desktopMode: true,
    placeholder: "Procure por c\xF3digo, nome, marca...",
    onSearch: onSearch,
    onSuggestItemClick: handleSuggestionClick,
    onTypeChange: handleTermTypeChange,
    onInputOpen: onOpenInput,
    initialValue: term,
    list: suggestions,
    autoFocus: true
  })), React.createElement(Space, null), isLogged ? React.createElement(LoggedMenu, {
    data: loggedMenu,
    isPromoter: isPromoter,
    userName: userData.name
  }) : React.createElement(LogInMenu, {
    data: logInMenu,
    theme: structure == null ? void 0 : structure.theme
  }), React.createElement(Cart, {
    "data-testid": "header-shopping-button"
  }, React.createElement(ShoppingBagButton, {
    nullable: false,
    amount: 0,
    onClick: function onClick() {
      return routePush({
        path: staticProps.cartUrl
      });
    }
  }))), React.createElement(CategoriesContainer, null, React.createElement(Categories, {
    allCategories: allCategories,
    highlighted: highlighted,
    storeName: store.name
  })));
}
process.env.NODE_ENV !== "production" ? HeaderDesktopPMD.propTypes = {
  data: shape({}),
  "static": shape({
    cartUrl: string,
    loggedMenu: shape({}),
    logInMenu: shape({}),
    store: shape({
      image: oneOf([null, string]),
      name: string
    })
  }),
  structure: shape({})
} : void 0;
HeaderDesktopPMD.defaultProps = {
  data: {},
  "static": {
    cartUrl: '',
    loggedMenu: {},
    logInMenu: {},
    loyalty: {},
    store: {
      image: null,
      name: ''
    }
  },
  structure: {}
};
HeaderDesktopPMD.dataSource = {
  categories: {
    query: 'AllCategoriesQuery'
  },
  channel: {
    query: 'ChannelQuery'
  }
};
HeaderDesktopPMD.ssr = true;
HeaderDesktopPMD.displayName = 'HeaderDesktopPMD';
export default withLayoutProps(HeaderDesktopPMD);