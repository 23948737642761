import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["items", "TitleText", "titleProps", "DescriptionText", "spa", "ga"];
import React from 'react';
import { arrayOf, shape } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import Text from '@magalu/stereo-ui/atoms/Text';
import StereoHotLinks from '@magalu/stereo-ui/molecules/HotLinks';
import { usePublisher } from '@magalu/mixer-publisher';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
var HotLinks = function HotLinks(_ref) {
  var staticProps = _ref["static"];
  var _staticProps$items = staticProps.items,
    items = _staticProps$items === void 0 ? [] : _staticProps$items,
    TitleText = staticProps.TitleText,
    _staticProps$titlePro = staticProps.titleProps,
    titleProps = _staticProps$titlePro === void 0 ? {} : _staticProps$titlePro,
    DescriptionText = staticProps.DescriptionText,
    _staticProps$spa = staticProps.spa,
    spa = _staticProps$spa === void 0 ? false : _staticProps$spa,
    _staticProps$ga = staticProps.ga,
    ga = _staticProps$ga === void 0 ? {} : _staticProps$ga,
    props = _objectWithoutProperties(staticProps, _excluded);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handleItemClick = function handleItemClick(_e, _ref2) {
    var text = _ref2.text,
      link = _ref2.link;
    publish('hotlink:click', {
      ga: ga,
      text: text
    });
    if (spa) {
      _e.preventDefault();
      routePush({
        path: link,
        spa: true
      });
    }
  };
  var parseItems = items == null ? void 0 : items.map(function () {
    var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      id = _ref3.id,
      _ref3$link = _ref3.link;
    _ref3$link = _ref3$link === void 0 ? {} : _ref3$link;
    var text = _ref3$link.text,
      href = _ref3$link.href,
      src = _ref3.image.src;
    return {
      id: id,
      image: src,
      link: href,
      text: text
    };
  });
  return React.createElement(Box, _extends({
    as: "section",
    p: "15px 8px",
    bg: "white"
  }, props), TitleText && React.createElement(Heading, _extends({
    as: "h2",
    m: "10px 0",
    fontSize: [4, 1, 1]
  }, titleProps), TitleText), DescriptionText && React.createElement(Text, {
    mb: "20px"
  }, DescriptionText), React.createElement(StereoHotLinks, {
    items: parseItems,
    onItemClick: handleItemClick
  }));
};
HotLinks.defaultProps = {
  "static": {
    items: []
  }
};
process.env.NODE_ENV !== "production" ? HotLinks.propTypes = {
  "static": shape({
    items: arrayOf(shape({}))
  })
} : void 0;
HotLinks.ssr = true;
export default withLayoutProps(HotLinks);