import { parseCanonical, slug } from '@magalu/mixer-utils';
var parseHeaderData = function parseHeaderData(_ref) {
  var _data$allCategories, _menuData$data, _menuData$data$catego, _menuData$data2, _menuData$data2$accou, _data$channel, _data$channel$data, _data$channel$data$fi, _menuData$data3;
  var staticProps = _ref.staticProps,
    structure = _ref.structure,
    data = _ref.data;
  var _staticProps$menuData = staticProps.menuData,
    menuData = _staticProps$menuData === void 0 ? {
      data: data
    } : _staticProps$menuData,
    headerData = staticProps.headerData;
  var _ref2 = structure.cookies || {},
    _ref2$accountData = _ref2.accountData,
    accountData = _ref2$accountData === void 0 ? {} : _ref2$accountData;
  var parserPmd = menuData.data.pmdHeader;
  var categoryList = (data == null ? void 0 : (_data$allCategories = data.allCategories) == null ? void 0 : _data$allCategories.map(function (_ref3) {
    var _structure$config;
    var id = _ref3.id,
      label = _ref3.label,
      description = _ref3.description,
      meta = _ref3.meta;
    return {
      id: id,
      label: label,
      url: ((structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.domain) || '') + (parseCanonical(meta == null ? void 0 : meta.canonical) || "/".concat(slug(description), "/l/").concat(slug(id), "/"))
    };
  })) || [];
  if ((menuData == null ? void 0 : (_menuData$data = menuData.data) == null ? void 0 : (_menuData$data$catego = _menuData$data.categoryList) == null ? void 0 : _menuData$data$catego.list) !== undefined && parserPmd) {
    menuData.data.categoryList.list = categoryList;
  }
  if ((menuData == null ? void 0 : (_menuData$data2 = menuData.data) == null ? void 0 : (_menuData$data2$accou = _menuData$data2.account) == null ? void 0 : _menuData$data2$accou.name) !== undefined && (accountData == null ? void 0 : accountData.name)) {
    menuData.data.account.name = accountData.name;
  }
  var showcases = (data == null ? void 0 : (_data$channel = data.channel) == null ? void 0 : (_data$channel$data = _data$channel.data) == null ? void 0 : (_data$channel$data$fi = _data$channel$data.find(function (item) {
    return item.name === 'showcases';
  })) == null ? void 0 : _data$channel$data$fi.value) || [];
  if ((menuData == null ? void 0 : menuData.data) && showcases && showcases.length) {
    menuData.data.showcaseLinks = showcases;
  }
  if ((accountData == null ? void 0 : accountData.name) && (menuData == null ? void 0 : (_menuData$data3 = menuData.data) == null ? void 0 : _menuData$data3.header)) {
    menuData.data.header.label = menuData.data.header.loggedLabel;
    menuData.data.header.url = menuData.data.header.loggedUrl;
  }
  return {
    headerData: headerData,
    menuData: menuData
  };
};
export default parseHeaderData;