import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
import styled from 'styled-components';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import StereoSearchInput from '@magalu/stereo-ui/molecules/SearchInput';
import { getPaletteColor, themeGet } from '@magalu/stereo-ui-styles/build/utils';
export var AlignIcon = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  gap: 4px;\n  position: absolute;\n  right: 20px;\n  top: 50%;\n  transform: translateY(-50%);\n\n  > * {\n    padding: 8px;\n    cursor: pointer;\n  }\n"])));
export var ContainerButton = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-left: -8px;\n\n  > * {\n    padding: 8px;\n    cursor: pointer;\n  }\n"])));
export var Content = styled(Flex)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-items: center;\n  background: ", ";\n  height: ", ";\n  color: ", ";\n  padding: 0 16px;\n  position: relative;\n"])), function (props) {
  return themeGet('palette.background.primary')(props);
}, function (props) {
  return themeGet('sizes.header')(props);
}, function (props) {
  return themeGet('palette.background.white')(props);
});
export var HeaderTitle = styled.h1(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: block;\n  font-size: ", "px;\n  font-weight: ", ";\n  line-height: ", ";\n  margin-left: 18px;\n"])), function (props) {
  return themeGet('fontSizes.4')(props);
}, function (props) {
  return themeGet('fontWeights.regular')(props);
}, function (props) {
  return themeGet('lineHeights.fontSize')(props);
});
export var LogoPrefix = styled.strong(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: ", ";\n  margin-right: 2px;\n"])), function (props) {
  return getPaletteColor('primary.dark')(props);
});
export var LogoText = styled(Heading).attrs(function (props) {
  return _extends({
    color: 'text',
    fontSize: 1,
    fontWeight: 'bold',
    lineHeight: "".concat(themeGet('lineHeights.tall')(props), "px"),
    marginLeft: '20px'
  }, props);
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));
export var SearchInput = styled(StereoSearchInput)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  margin-left: 5px;\n"])));