var moment = require('moment');
var subtractMonths = function subtractMonths(date, months) {
  var momentDate = moment(date, 'YYYY-MM-DD HH:mm:ss.SSS');
  var originalDay = momentDate.date();
  momentDate.subtract(months, 'months');
  if (momentDate.date() < originalDay) {
    momentDate.endOf('month');
  } else {
    momentDate.date(originalDay);
  }
  return momentDate.format('YYYY-MM-DD HH:mm:ss.SSS');
};
export default subtractMonths;