import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
import styled, { css } from 'styled-components';
import { getBackgroundColor } from '@magalu/stereo-ui-styles';
import BaseButton from '@magalu/stereo-ui/atoms/BaseButton';
import Image from '@magalu/stereo-ui/atoms/ResponsiveImage';
export var GalleryImagesContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  min-width: 65%;\n  max-height: 650px;\n  height: 64%;\n"])));
export var GalleryThumbButton = styled(BaseButton).attrs({
  role: 'tab',
  type: 'button'
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  border: 1px solid;\n  border-color: ", ";\n  max-height: 85px;\n  max-width: 96px;\n  margin: 0 1.5rem 1.5rem 0.5rem;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: ", ";\n  img {\n    max-width: 90%;\n  }\n"])), function (props) {
  return props.active ? getBackgroundColor('primary')(props) : 'transparent';
}, function (_ref) {
  var isImage = _ref.isImage;
  return isImage ? '2px' : '8px';
});
export var PodcastAudio = styled.audio(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
export var PodcastImage = styled.img(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  height: 193px;\n  margin-bottom: 1rem;\n  width: 193px;\n"])), function (props) {
  return getBackgroundColor('luContentPrimary')(props);
});
export var ZoomContainer = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  overflow: hidden;\n  margin: 0 auto;\n"])));
export var ZoomImage = styled(Image)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  cursor: ", ";\n  max-height: 650px;\n\n  ", "\n"])), function (props) {
  return props.activeZoom ? 'grab' : 'zoom-in';
}, function (props) {
  return props.activeZoom ? css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n          height: 1500px;\n          max-height: none;\n          width: 1500px;\n        "]))) : css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n          height: 400px;\n          width: 600px;\n        "])));
});