var mapProductSuggestions = function mapProductSuggestions(products) {
  return products.map(function (_ref) {
    var variationId = _ref.variationId,
      image = _ref.image,
      title = _ref.title,
      price = _ref.price,
      path = _ref.path;
    return {
      id: variationId,
      img: image == null ? void 0 : image.replace('{w}x{h}', '59x44'),
      path: path,
      price: price.realPrice,
      term: title
    };
  });
};
var mapTermsSuggestions = function mapTermsSuggestions(terms) {
  return terms.map(function (suggestion) {
    return {
      term: suggestion.term
    };
  });
};

export default (function (_ref2) {
  var terms = _ref2.terms,
    products = _ref2.products;
  return {
    searchSuggestions: {
      products: mapProductSuggestions(products),
      terms: mapTermsSuggestions(terms)
    }
  };
});