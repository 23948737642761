import React from 'react';
import { bool, func } from 'prop-types';
import IconButton from '@magalu/stereo-ui/molecules/IconButton';
import { ArrowBack, Burger } from '@magalu/stereo-ui-icons';
import { ContainerButton } from './HeaderPME.styles';
function MenuPME(_ref) {
  var backButton = _ref.backButton,
    goBack = _ref.goBack,
    openMenu = _ref.openMenu;
  if (backButton) {
    return React.createElement(ContainerButton, null, React.createElement(IconButton, {
      name: "ArrowBack",
      onClick: goBack
    }, React.createElement(ArrowBack, {
      role: "button",
      "aria-label": "Voltar a P\xE1gina",
      "data-testid": "button-back",
      color: "#fff"
    })));
  }
  return React.createElement(ContainerButton, null, React.createElement(IconButton, {
    name: "Burger",
    onClick: openMenu
  }, React.createElement(Burger, {
    role: "button",
    "aria-label": "abrir o menu",
    "data-testid": "button-burger",
    color: "#fff"
  })));
}
process.env.NODE_ENV !== "production" ? MenuPME.propTypes = {
  backButton: bool,
  goBack: func,
  openMenu: func
} : void 0;
MenuPME.defaultProps = {
  backButton: false,
  goBack: function goBack() {},
  openMenu: function openMenu() {}
};
MenuPME.displayName = 'MenuPME';
export default MenuPME;