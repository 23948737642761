import React from 'react';
import { shape, string, oneOf } from 'prop-types';
import LogoParceiroMagalu from '@magalu/stereo-ui/atoms/LogoParceiroMagalu';
import { Container, ProfileImage, Logo } from './Profile.styles';
function Profile(_ref) {
  var store = _ref.store;
  var lowerStoreName = (store.name || '').toLowerCase();
  return React.createElement(Container, {
    href: "/magazine".concat(lowerStoreName),
    "data-testid": "header-profile-button"
  }, store.image ? React.createElement(ProfileImage, {
    src: "".concat(store.image, "?width=70&height=70"),
    alt: "Imagem da loja magazine".concat(lowerStoreName)
  }) : null, React.createElement(Logo, null, React.createElement(LogoParceiroMagalu, {
    name: lowerStoreName
  })));
}
process.env.NODE_ENV !== "production" ? Profile.propTypes = {
  store: shape({
    image: oneOf([null, string]),
    name: string
  })
} : void 0;
Profile.defaultProps = {
  store: {
    image: null,
    name: ''
  }
};
export default Profile;