import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["media"];
import React from 'react';
import Head from 'next/head';
import { bool, shape, string, number } from 'prop-types';
import StereoMediaGallery from '@magalu/stereo-ui/organisms/MediaGallery';
import { getFullTitle, replaceSizeImage } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container } from './MediaGallery.styles';
var MediaGallery = function MediaGallery(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _data$product = data.product,
    media = _data$product.media,
    product = _objectWithoutProperties(_data$product, _excluded);
  var audios = [].concat(_toConsumableArray((media == null ? void 0 : media.audios) || []), _toConsumableArray((media == null ? void 0 : media.podcasts) || []));
  var imgWidth = staticProps.imgWidth,
    imgHeight = staticProps.imgHeight,
    imgMaxHeight = staticProps.imgMaxHeight,
    mediaGalleryModal = staticProps.mediaGalleryModal,
    preload = staticProps.preload,
    showThumbnail = staticProps.showThumbnail;
  var mediaGalleryAlt = "Imagem de ".concat(product.title);
  var fullTitle = getFullTitle(product);
  var _ref2 = (media == null ? void 0 : media.images) || [],
    _ref3 = _slicedToArray(_ref2, 1),
    firstMedia = _ref3[0];
  var src = replaceSizeImage({
    imgHeight: imgHeight,
    imgWidth: imgWidth,
    src: firstMedia
  });
  var handleImageClick = function handleImageClick(idx) {
    if (mediaGalleryModal) {
      var _media$videos;
      var videos = (media == null ? void 0 : (_media$videos = media.videos) == null ? void 0 : _media$videos.length) || 0;
      var podcasts = (audios == null ? void 0 : audios.length) || 0;
      var selectedImageIdx = idx < 0 ? 0 : idx;
      var mediaImageIdx = videos + podcasts + selectedImageIdx;
      publish('mediagallerymodal:toggle', mediaImageIdx);
    }
  };
  var handleMediaClick = function handleMediaClick(type) {
    if (mediaGalleryModal) {
      var _media$videos2;
      var mediaTypes = {
        audio: (media == null ? void 0 : (_media$videos2 = media.videos) == null ? void 0 : _media$videos2.length) + 0,
        video: 0
      };
      publish('mediagallerymodal:toggle', mediaTypes[type]);
    }
  };
  return React.createElement(Container, null, preload && src && React.createElement(Head, null, React.createElement("link", {
    rel: "preload",
    as: "image",
    href: src
  })), React.createElement(StereoMediaGallery, _extends({
    lazy: true,
    m: ['0 8px', '0px'],
    alt: mediaGalleryAlt,
    title: fullTitle,
    imgWidth: imgWidth,
    imgHeight: imgHeight,
    imgMaxHeight: imgMaxHeight,
    showThumbnail: showThumbnail,
    preload: preload,
    onImageClick: handleImageClick,
    onVideoClick: function onVideoClick() {
      return handleMediaClick('video');
    },
    onAudioClick: function onAudioClick() {
      return handleMediaClick('audio');
    }
  }, media)));
};
process.env.NODE_ENV !== "production" ? MediaGallery.propTypes = {
  data: shape({
    product: shape({
      media: shape({}),
      mediaGalleryAlt: string,
      reference: string,
      title: string
    })
  }),
  "static": shape({
    imgHeight: number,
    imgMaxHeight: string,
    imgWidth: number,
    mediaGalleryModal: bool,
    preload: bool,
    showThumbnail: bool
  })
} : void 0;
MediaGallery.defaultProps = {
  data: {},
  "static": {}
};
MediaGallery.ssr = true;
MediaGallery.displayName = 'MediaGallery';
export default withLayoutProps(MediaGallery);