import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import styled from 'styled-components';
import { themeGet, getPaletteColor, getTextColor, getBackgroundColor, getFontSize } from '@magalu/stereo-ui-styles';
export var AllDepartments = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: 5px 0 0 0;\n  background-color: ", ";\n  padding: 8px 12px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  border: none;\n  color: ", ";\n  fill: ", ";\n\n  & span {\n    font-weight: ", ";\n    margin: 0 10px 0 15px;\n    font-size: ", "px;\n  }\n  &:focus,\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n    fill: ", ";\n  }\n"])), getPaletteColor('pmd.lightest'), getTextColor('lightest'), getTextColor('lightest'), themeGet('fontWeight.bold'), getFontSize(1), getPaletteColor('pmdLight.base'), getPaletteColor('pmdLight.text'), getPaletteColor('pmdLight.text'));
export var Container = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  height: 40px;\n"])));
export var MainCategory = styled.a(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  text-decoration: none;\n  font-size: ", "px;\n  padding: 12px 14px;\n  height: 40px;\n  display: inline-block;\n  cursor: pointer;\n  color: ", ";\n  &:focus,\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n"])), getFontSize(1), getTextColor('lightest'), getBackgroundColor('lighter'), getPaletteColor('pmd.dark'));
export var MainMenu = styled.ul(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  height: 40px;\n  display: flex;\n  flex-wrap: wrap;\n  overflow: hidden;\n  border-radius: 5px 5px 0 0;\n  color: ", ";\n\n  li {\n    list-style: none;\n  }\n"])), getPaletteColor('pmd.light'), getTextColor('lightest'));
export var SubCategory = styled.a(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  text-decoration: none;\n  line-height: 20px;\n  display: inline-block;\n  padding: 5px;\n  color: ", ";\n  font-size: ", "px;\n  cursor: pointer;\n\n  &:hover {\n    text-decoration: underline;\n  }\n"])), getTextColor('scratched'), getFontSize(1));
export var SubMenu = styled.ul(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: ", ";\n  visibility: ", ";\n  background-color: ", ";\n  border: ", ";\n  border-top: 0;\n  border-bottom: 4px solid ", ";\n  border-radius: 0 0 5px 5px;\n  padding: 20px 25px;\n  grid-column-gap: 35px;\n  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));\n  &:hover {\n    display: grid;\n  }\n\n  li {\n    list-style: none;\n  }\n"])), function (_ref) {
  var display = _ref.display;
  return display ? 'grid' : 'none';
}, function (_ref2) {
  var display = _ref2.display;
  return display ? 'visible' : 'hidden';
}, getBackgroundColor('lighter'), themeGet('borders.0'), getPaletteColor('pmd.base'));