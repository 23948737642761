import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import { bool, shape, string } from 'prop-types';
import Router from 'next/router';
import SearchInput from '@magalu/stereo-ui/molecules/SearchInput';
import IconButton from '@magalu/stereo-ui/molecules/IconButton';
import { Search, Share } from '@magalu/stereo-ui-icons';
import { useSuggestions } from '@magalu/mixer-graphql';
import { routePush, slug } from '@magalu/mixer-utils';
import { termToUrl } from '@magalu/mixer-structure';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import MenuPME from './MenuPME';
import { AlignIcon, Content, HeaderTitle, LogoPrefix, LogoText } from './HeaderPME.styles';
var FIRST_INDEX = 0;
var getClearName = function getClearName() {
  var storeName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var prefix = arguments.length > 1 ? arguments[1] : undefined;
  return storeName.indexOf(prefix) === FIRST_INDEX ? storeName.replace(prefix, '') : storeName;
};
var formatNewTermUrl = function formatNewTermUrl(newTerm, searchUrl) {
  var termStr = newTerm ? "".concat(termToUrl(slug(newTerm)), "/") : '';
  return "".concat(searchUrl, "/").concat(termStr).replace('//', '/');
};
var handleShare = function handleShare(message) {
  var text = message.replace(/\\n/g, '\n');
  if (navigator.share) {
    navigator.share({
      text: text
    });
  } else {
    window.open("https://web.whatsapp.com/send?text=".concat(encodeURIComponent(text)), '_blank');
  }
};
var renderShareButton = function renderShareButton(message) {
  return message && React.createElement(IconButton, {
    name: "Share",
    onClick: function onClick() {
      return handleShare(message);
    }
  }, React.createElement(Share, {
    role: "button",
    "aria-label": "Compartilhar",
    "data-testid": "button-share",
    color: "#fff"
  }));
};
function HeaderPME(_ref) {
  var _structure$route4;
  var staticProps = _ref["static"],
    structure = _ref.structure;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var backButton = staticProps.backButton,
    headerData = staticProps.headerData,
    prefixLogo = staticProps.prefixLogo,
    searchUrl = staticProps.searchUrl,
    title = staticProps.title,
    shareMessage = staticProps.shareMessage;
  var _structure$route = structure.route,
    route = _structure$route === void 0 ? {} : _structure$route,
    _structure$cookies = structure.cookies,
    cookies = _structure$cookies === void 0 ? {} : _structure$cookies;
  var _route$term = route.term,
    initialQuery = _route$term === void 0 ? '' : _route$term,
    storeName = route.storeId;
  var partnerId = cookies.partnerId;
  var _useSuggestions = useSuggestions({
      partnerId: partnerId
    }),
    suggestions = _useSuggestions.suggestions,
    getSuggestions = _useSuggestions.getSuggestions,
    cleanSuggestions = _useSuggestions.cleanSuggestions;
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    chosenSuggestion = _useState2[0],
    setChosenSuggestion = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    openSearch = _useState4[0],
    setOpenSearch = _useState4[1];
  var _useState5 = useState(initialQuery),
    _useState6 = _slicedToArray(_useState5, 2),
    term = _useState6[0],
    setTerm = _useState6[1];
  useEffect(function () {
    if (openSearch) {
      var input = document.getElementById('input-search');
      if (input) {
        input.click();
      }
    }
  }, [openSearch]);
  var publishSuggestionFromRoute = function publishSuggestionFromRoute(suggestion) {
    var position = suggestions.findIndex(function (item) {
      return item.term === suggestion;
    }) + 1;
    publish('suggestion:click', {
      position: position,
      term: suggestion
    });
  };
  useEffect(function () {
    var _structure$route2;
    if (chosenSuggestion && chosenSuggestion.includes(structure == null ? void 0 : (_structure$route2 = structure.route) == null ? void 0 : _structure$route2.term)) {
      var _structure$route3;
      publishSuggestionFromRoute(structure == null ? void 0 : (_structure$route3 = structure.route) == null ? void 0 : _structure$route3.term);
    }
  }, [structure == null ? void 0 : (_structure$route4 = structure.route) == null ? void 0 : _structure$route4.term, chosenSuggestion]);
  var handleBack = function handleBack() {
    Router.back();
  };
  var onSearch = function onSearch(e, newTerm) {
    e.preventDefault();
    var newTermTrim = newTerm.trim();
    if (e.type === 'submit' && newTermTrim) {
      publish('search:submit', {
        term: newTermTrim
      });
    }
    var newTermUrl = formatNewTermUrl(newTermTrim, searchUrl);
    setOpenSearch(false);
    routePush({
      path: newTermUrl,
      spa: true
    });
  };
  var handleOpenMenu = function handleOpenMenu() {
    return window.parent.postMessage('open menu pme', '*');
  };
  var handleSuggest = function handleSuggest(suggestionsTerm) {
    getSuggestions({
      term: suggestionsTerm
    });
  };
  var handleSuggestionClick = function handleSuggestionClick(suggestion) {
    setChosenSuggestion(suggestion);
  };
  var onOpenInput = function onOpenInput() {
    if (term.trim() && !suggestions.length) {
      handleSuggest(term);
    }
  };

  var onCloseInput = function onCloseInput() {
    publish('search:close', {
      term: term
    });
    setOpenSearch(false);
  };
  var onClearInput = function onClearInput() {
    publish('search:clear', {
      term: term
    });
    cleanSuggestions();
  };
  var handleTermTypeChange = function handleTermTypeChange(newTerm) {
    setTerm(newTerm);
    handleSuggest(newTerm);
  };
  if (openSearch) {
    var _headerData$search;
    return React.createElement(SearchInput, {
      onSearch: onSearch,
      onInputClear: onClearInput,
      onSuggestItemClick: handleSuggestionClick,
      onTypeChange: handleTermTypeChange,
      onInputClose: onCloseInput,
      onInputOpen: onOpenInput,
      placeholder: headerData == null ? void 0 : (_headerData$search = headerData.search) == null ? void 0 : _headerData$search.placeholder,
      initialValue: term,
      list: suggestions,
      autoFocus: true
    });
  }
  return React.createElement("header", null, React.createElement(Content, null, React.createElement(MenuPME, {
    backButton: backButton,
    goBack: handleBack,
    openMenu: handleOpenMenu
  }), React.createElement("div", null, React.createElement(HeaderTitle, null, title), React.createElement(LogoText, null, React.createElement(LogoPrefix, null, prefixLogo), getClearName(storeName, prefixLogo))), React.createElement(AlignIcon, null, React.createElement(IconButton, {
    name: "Search",
    onClick: function onClick() {
      return setOpenSearch(true);
    }
  }, React.createElement(Search, {
    role: "button",
    "aria-label": "abrir a busca",
    "data-testid": "button-search",
    color: "#fff"
  })), renderShareButton(shareMessage))));
}
process.env.NODE_ENV !== "production" ? HeaderPME.propTypes = {
  "static": shape({
    backButton: bool,
    headerData: shape({}),
    prefixLogo: string,
    searchUrl: string,
    title: string
  }),
  structure: shape({})
} : void 0;
HeaderPME.defaultProps = {
  "static": {
    backButton: false,
    headerData: {},
    prefixLogo: 'parceiro',
    searchUrl: '/busca/',
    title: 'Vender'
  },
  structure: {}
};
HeaderPME.dataSource = {
  categories: {
    query: 'AllCategoriesQuery'
  },
  channel: {
    query: 'ChannelQuery'
  }
};
HeaderPME.ssr = true;
HeaderPME.displayName = 'HeaderPME';
export default withLayoutProps(HeaderPME);