import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import getConfig from 'next/config';
import { Icon } from '@magalu/stereo-ui-icons';
import { PageLoading } from '@magalu/stereo-ui/atoms';
import { useEligibleProducts, useProductById } from '@magalu/mixer-graphql';
import { usePublisher } from '@magalu/mixer-publisher';
import { useCookies, routePush } from '@magalu/mixer-utils';
import ConfirmDialog from '../ConfirmDialog';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { ArrowBox, ArrowIcon, ContainerIcon, LinkContainer, Image, ImageBox, PageContainer, ProductTitle, TextContainer, Title, ButtonSeeMore, TextEmptyMessage } from './OrderEligibleList.styles';
import getProductUrl from './utils/getProductUrl';
import getServiceSlug from './utils/getServiceSlug';
import replaceTitle from './utils/replaceTitle';
import subtractMonths from './utils/subtractMonths';
var OrderEligibleList = function OrderEligibleList(_ref) {
  var staticProps = _ref["static"],
    structure = _ref.structure;
  var title = staticProps.title,
    imageWidth = staticProps.imageWidth,
    imageHeight = staticProps.imageHeight,
    fontSize = staticProps.fontSize,
    modalNotEligible = staticProps.modalNotEligible,
    monthIntervals = staticProps.monthIntervals,
    basePaths = staticProps.basePaths,
    noHasMoreProducts = staticProps.noHasMoreProducts,
    seeMoreText = staticProps.seeMoreText,
    displayMode = staticProps.displayMode;
  var config = getConfig();
  var _useCookies = useCookies({}, config),
    accountData = _useCookies.accountData;
  var customerId = accountData.id;
  var route = structure.route;
  var TODAY_DATE = subtractMonths(new Date(), 0);
  var PASS_DATE = subtractMonths(new Date(), monthIntervals[0]);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var icon = modalNotEligible.icon;
  var _useProductById = useProductById(route.productId),
    product = _useProductById.product;
  var serviceSlug = getServiceSlug(product == null ? void 0 : product.factsheet);
  var baseFinancialServiceUrl = "".concat(basePaths.financialService, "/?serviceSlug=").concat(serviceSlug);
  var _useState = useState(TODAY_DATE),
    _useState2 = _slicedToArray(_useState, 2),
    createdAtLesser = _useState2[0],
    setCreatedAtLesser = _useState2[1];
  var _useState3 = useState(PASS_DATE),
    _useState4 = _slicedToArray(_useState3, 2),
    createdAtGreater = _useState4[0],
    setCreatedAtGreater = _useState4[1];
  var _useState5 = useState(1),
    _useState6 = _slicedToArray(_useState5, 2),
    monthCount = _useState6[0],
    setMonthCount = _useState6[1];
  var _useState7 = useState(true),
    _useState8 = _slicedToArray(_useState7, 2),
    isLoading = _useState8[0],
    setIsLoading = _useState8[1];
  var _useState9 = useState([]),
    _useState10 = _slicedToArray(_useState9, 2),
    productsList = _useState10[0],
    setProductsList = _useState10[1];
  var isMonthValided = function isMonthValided() {
    return monthCount < monthIntervals.length;
  };
  var _useEligibleProducts = useEligibleProducts({
      createdAtGreater: createdAtGreater,
      createdAtLesser: createdAtLesser,
      customerId: customerId,
      serviceSlug: serviceSlug
    }),
    eligibleProducts = _useEligibleProducts.eligibleProducts,
    error = _useEligibleProducts.error,
    loading = _useEligibleProducts.loading;
  var handleSeeMorePage = function handleSeeMorePage() {
    if (isMonthValided()) {
      setMonthCount(function (prev) {
        return prev + 1;
      });
    } else {
      setIsLoading(false);
    }
  };
  var handleEligibleSetProducts = function handleEligibleSetProducts(eProducts) {
    if ((eProducts == null ? void 0 : eProducts.products.length) === 0) {
      handleSeeMorePage();
    } else if (eProducts) {
      setProductsList(function (prev) {
        return [].concat(_toConsumableArray(prev), _toConsumableArray(eProducts.products));
      });
      setIsLoading(false);
    }
  };
  useEffect(function () {
    if (!isLoading) {
      if (productsList == null ? void 0 : productsList.length) {
        publish('order-eligible-list:impression', {
          displayMode: displayMode,
          products: productsList,
          serviceSlug: serviceSlug
        });
      } else {
        publish('order-not-eligible:impression', {
          serviceSlug: serviceSlug
        });
      }
    }
  }, [productsList, isLoading]);
  useEffect(function () {
    if (monthCount > 1) {
      setCreatedAtLesser(subtractMonths(TODAY_DATE, monthIntervals[monthCount - 2]));
      setCreatedAtGreater(subtractMonths(TODAY_DATE, monthIntervals[monthCount - 1]));
    }
  }, [monthCount]);
  useEffect(function () {
    !loading && handleEligibleSetProducts(eligibleProducts);
    error && setIsLoading(false);
  }, [eligibleProducts, error, loading]);
  var handleRedirectOnClick = function handleRedirectOnClick() {
    return routePush({
      path: basePaths.insuranceService,
      spa: true
    });
  };
  var handleSelectedItem = function handleSelectedItem(order, index) {
    publish('order-eligible-list:click', {
      displayMode: displayMode,
      itemSelected: order,
      itemIndex: index,
      serviceSlug: serviceSlug
    });
  };
  var renderHeaderIcon = function renderHeaderIcon() {
    return React.createElement(ContainerIcon, null, React.createElement(Icon, {
      name: icon.name,
      height: icon.height,
      width: icon.width,
      color: icon.color
    }));
  };
  var renderConfirmDialog = function renderConfirmDialog() {
    return React.createElement(ConfirmDialog, {
      open: true,
      onClose: handleRedirectOnClick,
      title: modalNotEligible.title,
      infoText: replaceTitle(modalNotEligible.describe, product == null ? void 0 : product.title),
      confirmText: modalNotEligible.confirmText,
      confirmAction: handleRedirectOnClick,
      headerComponent: renderHeaderIcon()
    });
  };
  var renderEligibleList = function renderEligibleList() {
    return React.createElement(React.Fragment, null, React.createElement(Title, {
      "data-testid": "order-eligible-list-title"
    }, title), productsList.map(function (order, index) {
      return React.createElement(LinkContainer, {
        key: order.orderUUID,
        href: getProductUrl(baseFinancialServiceUrl, order.productSku, order.subOrderUUID),
        "data-testid": "product-card-container",
        onClick: function onClick() {
          return handleSelectedItem(order, index);
        }
      }, React.createElement(ImageBox, null, React.createElement(Image, {
        imageWidth: imageWidth,
        imageHeight: imageHeight,
        src: order.productImageUrl,
        alt: order.productDescription,
        "data-testid": "product-card-img"
      })), React.createElement(TextContainer, {
        "data-testid": "product-card-section"
      }, React.createElement(ProductTitle, {
        fontSize: fontSize,
        "data-testid": "product-card-title"
      }, order.productDescription)), React.createElement(ArrowBox, null, React.createElement(ArrowIcon, {
        name: "ChevronRight"
      })));
    }), isMonthValided() ? React.createElement(ButtonSeeMore, {
      "data-testid": "order-eligible-list-see-more",
      type: "button",
      color: "primary",
      onClick: handleSeeMorePage
    }, seeMoreText) : React.createElement(TextEmptyMessage, null, noHasMoreProducts));
  };
  var renderContent = function renderContent() {
    return productsList.length ? renderEligibleList() : renderConfirmDialog();
  };
  return isLoading ? React.createElement(PageLoading, null) : React.createElement(PageContainer, {
    "data-testid": "order-eligible-list"
  }, renderContent());
};
var modalNotEligible = shape({
  confirmText: string,
  describe: string,
  icon: shape({
    color: string,
    height: string,
    name: string,
    width: string
  }),
  title: string
});
var basePaths = shape({
  financialService: string.isRequired,
  insuranceService: string.isRequired
}).isRequired;
var OrderEligibleListPropTypes = process.env.NODE_ENV !== "production" ? {
  "static": shape({
    basePaths: basePaths,
    displayMode: string,
    fontSize: number,
    imageHeight: string,
    imageWidth: string,
    modalNotEligible: modalNotEligible,
    monthIntervals: arrayOf(number),
    noHasMoreProducts: string,
    seeMoreText: string
  }).isRequired,
  structure: shape({
    cookies: shape({
      customerId: string
    }).isRequired,
    route: shape({})
  }).isRequired
} : {};
process.env.NODE_ENV !== "production" ? OrderEligibleList.propTypes = OrderEligibleListPropTypes : void 0;
export default withLayoutProps(OrderEligibleList);