import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
var zoomMiddle = function zoomMiddle(value) {
  return value * 0.25 * -1;
};
export default function useZoom(_ref) {
  var _ref$height = _ref.height,
    height = _ref$height === void 0 ? 0 : _ref$height,
    _ref$width = _ref.width,
    width = _ref$width === void 0 ? 0 : _ref$width;
  var initialZoomPosition = {
    clientX: zoomMiddle(width),
    clientY: zoomMiddle(height)
  };
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    zoom = _useState2[0],
    setZoom = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    zoomCoords = _useState4[0],
    setZoomCoords = _useState4[1];
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    zoomDragging = _useState6[0],
    setZoomDragging = _useState6[1];
  var _useState7 = useState(false),
    _useState8 = _slicedToArray(_useState7, 2),
    zoomMoved = _useState8[0],
    setZoomMoved = _useState8[1];
  var _useState9 = useState(initialZoomPosition),
    _useState10 = _slicedToArray(_useState9, 2),
    zoomPosition = _useState10[0],
    setZoomPosition = _useState10[1];
  var handleMove = useCallback(debounce(function (e) {
    if (!zoomDragging) return;
    if (!zoomMoved) setZoomMoved(true);
    setZoomPosition(function (prev) {
      var newX = prev.clientX + (e.clientX - zoomCoords.clientX);
      var newY = prev.clientY + (e.clientY - zoomCoords.clientY);
      var clientX = e.clientX,
        clientY = e.clientY;
      setZoomCoords({
        clientX: clientX,
        clientY: clientY
      });
      return {
        clientX: newX,
        clientY: newY
      };
    });
  }, 1), [zoomCoords, zoomDragging, zoomMoved]);
  var handleDown = useCallback(function (e, isZoomActive) {
    if (isZoomActive) {
      setZoomDragging(true);
      var clientX = e.clientX,
        clientY = e.clientY;
      setZoomCoords({
        clientX: clientX,
        clientY: clientY
      });
    }
  }, []);
  var handleUp = useCallback(function (isZoomActive, idx) {
    setZoomDragging(false);
    if (zoomMoved) return setZoomMoved(false);
    if (isZoomActive) {
      setZoomPosition(initialZoomPosition);
      return setZoom(null);
    }
    return setZoom(idx);
  }, [zoomMoved]);
  var handleLeave = useCallback(function () {
    return setZoomDragging(false);
  }, []);
  return {
    handleDown: handleDown,
    handleLeave: handleLeave,
    handleMove: handleMove,
    handleUp: handleUp,
    setZoom: setZoom,
    zoom: zoom,
    zoomDragging: zoomDragging,
    zoomPosition: zoomPosition
  };
}