import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["items", "titleText"],
  _excluded2 = ["value", "href"];
import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import { routePush } from '@magalu/mixer-utils';
import { termToUrl } from '@magalu/mixer-structure';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { List } from './LinkList.styles';
var LinkList = function LinkList(_ref) {
  var _items$filter;
  var id = _ref.id,
    _ref$static = _ref["static"],
    _ref$static$items = _ref$static.items,
    items = _ref$static$items === void 0 ? [] : _ref$static$items,
    titleText = _ref$static.titleText,
    props = _objectWithoutProperties(_ref$static, _excluded),
    basePath = _ref.structure.basePath;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var parseItems = Array.isArray(items) && (items == null ? void 0 : (_items$filter = items.filter(Boolean)) == null ? void 0 : _items$filter.map(function (_ref2) {
    var term = _ref2.term,
      value = _ref2.value,
      alt = _ref2.alt,
      href = _ref2.href;
    return {
      alt: term || alt,
      href: term ? "".concat(basePath, "/busca/").concat(termToUrl(term), "/") : "".concat(basePath).concat(href),
      value: term || value
    };
  }));
  var handleClick = function handleClick(e, href, value) {
    e.preventDefault();
    publish('listlink:click', {
      label: value
    });
    routePush({
      path: href,
      spa: true
    });
  };
  return (parseItems == null ? void 0 : parseItems.length) > 0 ? React.createElement(List, _extends({
    variant: "light",
    "data-testid": "link-list"
  }, props), titleText && React.createElement(List.Header, {
    "data-testid": "link-list-header"
  }, titleText), parseItems == null ? void 0 : parseItems.map(function (_ref3, index) {
    var value = _ref3.value,
      href = _ref3.href,
      itemProps = _objectWithoutProperties(_ref3, _excluded2);
    return React.createElement(List.Item, _extends({
      as: "a",
      scale: "small",
      key: "".concat(id, "-").concat(index + 1),
      href: href
    }, itemProps, {
      onClick: function onClick(e) {
        return handleClick(e, href, value);
      }
    }), value);
  })) : null;
};
process.env.NODE_ENV !== "production" ? LinkList.propTypes = {
  id: string,
  "static": shape({
    items: arrayOf(shape({
      value: string.isRequired
    }))
  }),
  structure: {
    basePath: string
  }
} : void 0;
LinkList.defaultProps = {
  id: 'list',
  "static": {},
  structure: {}
};
LinkList.ssr = true;
export default withLayoutProps(LinkList);