import React from 'react';
import { shape } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import LoggedMenu from './LoggedMenu';
import LogInMenu from './LogInMenu';
function LoginMenuPMD(_ref) {
  var customLoginMenu = _ref.customLoginMenu,
    structure = _ref.structure;
  var cookies = structure.cookies;
  var _customLoginMenu$logg = customLoginMenu.loggedMenu,
    loggedMenu = _customLoginMenu$logg === void 0 ? {} : _customLoginMenu$logg,
    _customLoginMenu$logI = customLoginMenu.logInMenu,
    logInMenu = _customLoginMenu$logI === void 0 ? {} : _customLoginMenu$logI;
  var userData = cookies.promoterData || cookies.accountData;
  var isLogged = userData || false;
  var isPromoter = isLogged && cookies.promoterData;
  var renderLoggedMenu = function renderLoggedMenu() {
    return React.createElement(LoggedMenu, {
      data: loggedMenu,
      isPromoter: isPromoter,
      userName: userData.name
    });
  };
  var renderLogInMenu = function renderLogInMenu() {
    return React.createElement(LogInMenu, {
      data: logInMenu,
      theme: structure == null ? void 0 : structure.theme
    });
  };
  return isLogged ? renderLoggedMenu() : renderLogInMenu();
}
LoginMenuPMD.propTypes = {
  customLoginMenu: shape({
    loggedMenu: shape({}),
    logInMenu: shape({})
  }),
  structure: shape({})
};
LoginMenuPMD.defaultProps = {
  customLoginMenu: {
    loggedMenu: {},
    logInMenu: {}
  },
  structure: {}
};
LoginMenuPMD.dataSource = {
  channel: {
    query: 'ChannelQuery'
  }
};
LoginMenuPMD.ssr = true;
LoginMenuPMD.displayName = 'LoginMenuPMD';
export default withLayoutProps(LoginMenuPMD);