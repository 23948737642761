import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useEffect } from 'react';
import { shape, bool, string } from 'prop-types';
import ImportTaxesComponent from '@magalu/stereo-ui/organisms/ImportTaxes';
import { usePublisher } from '@magalu/mixer-publisher';
import { useImportTax } from '@magalu/mixer-graphql';
import { getTaxes } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
var ImportTaxes = function ImportTaxes(_ref) {
  var data = _ref.data,
    showDialog = _ref["static"].showDialog;
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var _useImportTax = useImportTax(),
    getImportTax = _useImportTax.getImportTax,
    tax = _useImportTax.tax;
  var _usePublisher2 = usePublisher(),
    publish = _usePublisher2.publish;
  useEffect(function () {
    var handleEvent = function handleEvent(value) {
      return getTaxes(value, getImportTax);
    };
    subscribe('shippmment:finished', handleEvent);
    return function () {
      unsubscribe('shippmment:finished', handleEvent);
    };
  }, [subscribe, unsubscribe, getImportTax]);
  var onDetailsClose = function onDetailsClose() {
    publish('product:importTaxesClose:click');
  };
  var props = _extends({
    isIncludeTaxes: !!(tax == null ? void 0 : tax.importTax),
    onDetailsClose: onDetailsClose,
    showDialog: showDialog,
    totalValueICMS: tax == null ? void 0 : tax.icms,
    totalValueImportTax: tax == null ? void 0 : tax.importTax
  }, data);
  return React.createElement(ImportTaxesComponent, _extends({
    "data-testid": "import-taxes-component"
  }, props));
};
process.env.NODE_ENV !== "production" ? ImportTaxes.propTypes = {
  data: shape({
    product: shape({
      price: shape({
        currency: string
      })
    })
  }),
  "static": shape({
    showDialog: bool
  })
} : void 0;
ImportTaxes.defaultProps = {
  data: {},
  "static": {
    showDialog: false
  }
};
ImportTaxes.ssr = true;
export default withLayoutProps(ImportTaxes);