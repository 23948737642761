import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["items", "global", "event"],
  _excluded2 = ["value"];
import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import Container from '@magalu/stereo-ui/molecules/List';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
var List = function List(_ref) {
  var id = _ref.id,
    staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _staticProps$items = staticProps.items,
    items = _staticProps$items === void 0 ? [] : _staticProps$items,
    _staticProps$global = staticProps.global,
    global = _staticProps$global === void 0 ? {} : _staticProps$global,
    event = staticProps.event,
    props = _objectWithoutProperties(staticProps, _excluded);
  var handleClick = function handleClick(item) {
    if (event) {
      return publish(event, {
        category: id,
        item: item.value
      });
    }
    return publish('list-item:click', item);
  };
  return React.createElement(Container, _extends({
    variant: "light"
  }, props), items.map(function (_ref2, index) {
    var value = _ref2.value,
      itemProps = _objectWithoutProperties(_ref2, _excluded2);
    return React.createElement(Container.Item, _extends({
      as: "a",
      key: "".concat(id, "-").concat(index + 1)
    }, global, itemProps, {
      onClick: function onClick() {
        return handleClick(_extends({
          value: value
        }, itemProps));
      }
    }), value);
  }));
};
process.env.NODE_ENV !== "production" ? List.propTypes = {
  id: string,
  "static": shape({
    event: string,
    global: shape({}),
    items: arrayOf(shape({
      value: string.isRequired
    }))
  })
} : void 0;
List.defaultProps = {
  id: 'list',
  "static": {}
};
List.ssr = true;
export default withLayoutProps(List);