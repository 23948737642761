import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from 'styled-components';
import { flexbox } from 'styled-system';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Input from '@magalu/stereo-ui/atoms/Input';
import ResponsiveImage from '@magalu/stereo-ui/atoms/ResponsiveImage';
import { getBackgroundColor, getTextColor, themeGet } from '@magalu/stereo-ui-styles';
export var Container = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  background-color: ", ";\n  border-radius: 8px;\n  gap: 25px;\n"])), function (props) {
  return props.bgColor || getBackgroundColor('primary');
});
export var Form = styled.form(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  ", "\n"])), flexbox);
export var Image = styled(ResponsiveImage)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  border-radius: inherit;\n  width: 130px;\n  height: 112px;\n"])));
export var InputField = styled(Input)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  height: 44px;\n"])));
export var Small = styled.small(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  margin-top: ", "px;\n  color: ", ";\n"])), function (props) {
  return themeGet('space.1')(props);
}, getTextColor('lightest'));