import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["href", "preload", "spa"];
import React from 'react';
import { bool, number, oneOfType, shape, string } from 'prop-types';
import Head from 'next/head';
import StereoLink from '@magalu/stereo-ui/atoms/Link';
import ResponsiveImage from '@magalu/stereo-ui/atoms/ResponsiveImage';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
var Image = function Image(_ref) {
  var _ref$static = _ref["static"],
    href = _ref$static.href,
    preload = _ref$static.preload,
    spa = _ref$static.spa,
    staticProps = _objectWithoutProperties(_ref$static, _excluded);
  if (href) {
    var handleOnClick = function handleOnClick(e) {
      e.preventDefault();
      routePush({
        path: href,
        spa: spa
      });
    };
    return React.createElement(React.Fragment, null, preload && React.createElement(Head, null, React.createElement("link", {
      "data-testid": "preload",
      rel: "preload",
      as: "image",
      href: staticProps.src
    })), React.createElement(StereoLink, {
      "data-testid": "image-link",
      href: href,
      onClick: handleOnClick
    }, React.createElement(ResponsiveImage, staticProps)));
  }
  return React.createElement(ResponsiveImage, staticProps);
};
process.env.NODE_ENV !== "production" ? Image.propTypes = {
  "static": shape({
    height: oneOfType([string, number]),
    href: string,
    lazy: bool,
    preload: bool,
    spa: bool,
    src: string,
    width: oneOfType([string, number])
  })
} : void 0;
Image.defaultProps = {
  "static": {
    height: 'auto',
    href: '',
    lazy: true,
    spa: false,
    src: '',
    width: '100%'
  }
};
Image.ssr = true;
export default withLayoutProps(Image);