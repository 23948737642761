import React from 'react';
import { bool, shape, string } from 'prop-types';
import { Alert, Badge, Background, Container } from './Loyalty.styles';
function Loyalty(_ref) {
  var loyalty = _ref.loyalty;
  var slug = loyalty.slug,
    title = loyalty.title,
    isInfluencer = loyalty.isInfluencer;
  if (!slug || !title) {
    return null;
  }
  return React.createElement(Container, {
    tabIndex: "0",
    isInfluencer: isInfluencer
  }, React.createElement(Badge, {
    src: "https://mvc.mlcdn.com.br/magazinevoce/img/badges/badge_".concat(isInfluencer ? 'influencer' : slug, ".png"),
    alt: "Imagem da loja"
  }), !isInfluencer && React.createElement(Background, null, React.createElement("hr", null), React.createElement("div", null)), React.createElement(Alert, null, 'Esta é uma loja de nível ', React.createElement("span", null, isInfluencer ? 'Influenciador' : title)));
}
process.env.NODE_ENV !== "production" ? Loyalty.propTypes = {
  loyalty: shape({
    isInfluencer: bool,
    slug: string,
    title: string
  })
} : void 0;
Loyalty.defaultProps = {
  loyalty: {
    isInfluencer: false,
    slug: 'beginner',
    title: 'Novato'
  }
};
export default Loyalty;