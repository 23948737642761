import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape } from 'prop-types';
import StereoHeading from '@magalu/stereo-ui/atoms/Heading';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
var Heading = function Heading(_ref) {
  var staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var title = staticProps.title,
    msg = staticProps.publish;
  var handleClick = function handleClick() {
    if (msg) publish(msg);
  };
  return !title ? null : React.createElement(StereoHeading, _extends({
    as: "h1"
  }, staticProps, {
    onClick: handleClick
  }), title);
};
process.env.NODE_ENV !== "production" ? Heading.propTypes = {
  "static": shape({})
} : void 0;
Heading.defaultProps = {
  "static": {}
};
Heading.ssr = true;
export default withLayoutProps(Heading);