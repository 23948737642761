import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled, { css } from 'styled-components';
import { getTextColor } from '@magalu/stereo-ui-styles';
export var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 0 16px 8px;\n  ", "\n"])), function (_ref) {
  var isMobile = _ref.isMobile;
  return isMobile && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      padding: 0;\n      margin: 15px 12px;\n    "])));
});
export var Label = styled.label(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-width: 65%;\n  width: 100%;\n  height: 20px;\n  padding: 13px 0;\n  border: 0.5px solid #ccac00;\n  background-color: #fffdf0;\n  color: ", ";\n  border-radius: 8px;\n  flex-wrap: nowrap;\n  font-size: 16px;\n  ", "\n"])), function (props) {
  return getTextColor('base')(props);
}, function (_ref2) {
  var isMobile = _ref2.isMobile;
  return isMobile && css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      justify-content: flex-start;\n      padding: 15px 0px 15px 13px;\n    "])));
});