import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape, string } from 'prop-types';
import StereoLoyaltyCommission from '@magalu/stereo-ui/organisms/LoyaltyCommission';
import withLayoutProps from '../../../hocs/withLayoutProps';
var LoyaltyCommission = function LoyaltyCommission(_ref) {
  var _structure$config;
  var product = _ref.data.product,
    structure = _ref.structure,
    staticProps = _ref["static"];
  var domain = (staticProps == null ? void 0 : staticProps.domain) || (structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.domain);
  var loyalty = product.loyalty,
    url = product.url;
  var productUrl = domain + url;
  return React.createElement(StereoLoyaltyCommission, _extends({}, loyalty, {
    attributes: _extends({}, loyalty.attributes),
    productUrl: productUrl
  }));
};
process.env.NODE_ENV !== "production" ? LoyaltyCommission.propTypes = {
  data: shape({
    product: shape({
      loyalty: shape({
        attributes: shape({}),
        description: string,
        label: string,
        type: string,
        value: string
      })
    })
  }).isRequired,
  "static": shape({
    domain: string
  }),
  structure: shape({}).isRequired
} : void 0;
LoyaltyCommission.defaultProps = {
  "static": {}
};
LoyaltyCommission.dataSource = {
  product: {
    query: 'ProductQuery'
  }
};
export default withLayoutProps(LoyaltyCommission);