import React from 'react';
import { node, shape } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Box } from './Grid.styles';
var Grid = function Grid(_ref) {
  var StaticProps = _ref["static"],
    children = _ref.children;
  return React.createElement(Box, StaticProps, children);
};
process.env.NODE_ENV !== "production" ? Grid.propTypes = {
  children: node.isRequired,
  "static": shape({})
} : void 0;
Grid.defaultProps = {
  "static": {}
};
Grid.ssr = true;
export default withLayoutProps(Grid);