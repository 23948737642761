import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
import styled from 'styled-components';
import Link from '@magalu/stereo-ui/atoms/Link';
import { themeGet, getPaletteColor, getTextColor, getBackgroundColor, getFontSize } from '@magalu/stereo-ui-styles';
export var ButtonContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: 4px;\n  & button {\n    width: 140px;\n    & > label {\n      font-weight: ", ";\n    }\n  }\n"])), themeGet('fontWeight.regular'));
export var Container = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-right: 30px;\n  display: flex;\n"])));
export var Content = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  color: ", ";\n  line-height: 20px;\n\n  & > button {\n    padding: 0;\n    display: flex;\n  }\n"])), getFontSize(1), getTextColor('lightest'));
export var Menu = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: ", ";\n  position: absolute;\n  background-color: ", ";\n  border: ", ";\n  border-radius: ", ";\n  margin-top: 2px;\n  padding: 10px;\n  padding-bottom: 0;\n\n  & a {\n    cursor: pointer;\n    text-align: center;\n    font-size: ", "px;\n    color: ", ";\n    background: ", ";\n    text-decoration: none;\n    width: 100%;\n    display: inline-block;\n    padding-bottom: 10px;\n\n    &:hover {\n      color: ", ";\n    }\n  }\n"])), function (_ref) {
  var display = _ref.display;
  return display ? 'block' : 'none';
}, getBackgroundColor('lighter'), themeGet('borders.0'), themeGet('radii.default'), getFontSize(1), getTextColor('light'), getBackgroundColor('lighter'), getPaletteColor('pmd.base'));
export var NewButtonContainer = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  & button {\n    width: 200px;\n    margin-bottom: 8px;\n    & > label {\n      font-weight: ", ";\n    }\n  }\n\n  & hr {\n    border: 0;\n    border-top: 1px solid #eeeeee;\n    margin: 10px 0 5px 0;\n  }\n"])), themeGet('fontWeight.regular'));
export var PackagesContainer = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  border-top: ", ";\n\n  & div {\n    padding-left: 4px;\n    cursor: pointer;\n  }\n\n  & svg {\n    fill: ", ";\n    color: ", ";\n  }\n\n  & p {\n    color: ", ";\n  }\n"])), themeGet('borders.0'), getPaletteColor('pmd.dark'), getPaletteColor('pmd.dark'), getTextColor('scratched'));
export var StyledLink = styled(Link).attrs(function (props) {
  return _extends({
    color: '#fff'
  }, props);
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral([""])));
export var UserImage = styled.img(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  height: 47px;\n  width: 47px;\n  margin-right: 10px;\n  background-color: ", ";\n  border-radius: 100%;\n"])), getPaletteColor('pmd.dark'));