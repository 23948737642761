import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape } from 'prop-types';
import StereoHeading from '@magalu/stereo-ui/atoms/Heading';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
var HeadingSeller = function HeadingSeller(_ref) {
  var _currentSeller$seller;
  var data = _ref.data,
    staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var msg = staticProps.publish,
    sellerId = staticProps.sellerId;
  var _ref2 = (data == null ? void 0 : data.search) || {},
    products = _ref2.products;
  var handleClick = function handleClick() {
    if (msg) publish(msg);
  };
  var currentSeller = products == null ? void 0 : products.find(function (item) {
    var _item$seller;
    return (item == null ? void 0 : (_item$seller = item.seller) == null ? void 0 : _item$seller.id) === sellerId;
  });
  var title = currentSeller == null ? void 0 : (_currentSeller$seller = currentSeller.seller) == null ? void 0 : _currentSeller$seller.description;
  return !title ? null : React.createElement(StereoHeading, _extends({
    as: "h1"
  }, staticProps, {
    onClick: handleClick
  }), title);
};
process.env.NODE_ENV !== "production" ? HeadingSeller.propTypes = {
  data: shape({}),
  "static": shape({})
} : void 0;
HeadingSeller.defaultProps = {
  data: {},
  "static": {}
};
HeadingSeller.ssr = true;
export default withLayoutProps(HeadingSeller);