import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["brandSpa", "descriptionClick", "productSku", "productBrand", "subHeadingStatic"];
import React, { useEffect, useRef } from 'react';
import { shape, string, func, bool } from 'prop-types';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import Box from '@magalu/stereo-ui/atoms/Box';
import Text from '@magalu/stereo-ui/atoms/Text';
import Link from '@magalu/stereo-ui/atoms/Link';
import { usePublisher } from '@magalu/mixer-publisher';
import { routePush, scrollToRef, getFullTitle, slug as slugify } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Separator } from './styles';
var onLinkClick = function onLinkClick(link, brandSpa) {
  return function (e) {
    e.preventDefault();
    routePush({
      path: link,
      spa: brandSpa
    });
  };
};
function SubHeading(_ref) {
  var brandSpa = _ref.brandSpa,
    descriptionClick = _ref.descriptionClick,
    productSku = _ref.productSku,
    productBrand = _ref.productBrand,
    subHeadingStatic = _ref.subHeadingStatic,
    props = _objectWithoutProperties(_ref, _excluded);
  var skuPrefix = subHeadingStatic.skuPrefix,
    descriptionLink = subHeadingStatic.descriptionLink;
  return React.createElement(Box, _extends({
    as: "span",
    fontSize: 1
  }, props), React.createElement(Text, _extends({
    as: "span",
    color: "text.light"
  }, props), skuPrefix, " ", productSku), React.createElement(Separator, {
    color: "text.light"
  }), React.createElement(Link, _extends({
    onClick: descriptionClick,
    color: "text.light",
    underline: true
  }, props), descriptionLink), React.createElement(Separator, {
    color: "text.light"
  }), React.createElement(Link, _extends({
    onClick: onLinkClick(productBrand == null ? void 0 : productBrand.url, brandSpa),
    href: productBrand == null ? void 0 : productBrand.url,
    color: "text.light",
    underline: true
  }, props, {
    "data-testid": "heading-product-brand"
  }), productBrand == null ? void 0 : productBrand.label));
}
process.env.NODE_ENV !== "production" ? SubHeading.propTypes = {
  brandSpa: bool.isRequired,
  descriptionClick: func.isRequired,
  productBrand: shape({
    label: string,
    slug: string
  }).isRequired,
  productSku: string.isRequired,
  subHeadingStatic: shape({
    descriptionLink: string,
    skuPrefix: string
  }).isRequired
} : void 0;
var HeadingProduct = function HeadingProduct(_ref2) {
  var staticProps = _ref2["static"],
    data = _ref2.data,
    structure = _ref2.structure;
  var elemRef = useRef(null);
  var _structure$basePath = structure.basePath,
    basePath = _structure$basePath === void 0 ? '' : _structure$basePath;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish,
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var _ref3 = (data == null ? void 0 : data.product) || {},
    title = _ref3.title,
    productSku = _ref3.variationId,
    productBrand = _ref3.brand;
  var msg = staticProps.publish,
    _staticProps$brandSpa = staticProps.brandSpa,
    brandSpa = _staticProps$brandSpa === void 0 ? true : _staticProps$brandSpa,
    subHeading = staticProps.subHeading;
  var fullTitle = getFullTitle((data == null ? void 0 : data.product) || {});
  var handleClick = function handleClick() {
    if (msg) publish(msg);
  };
  var handleTitleClickEvent = function handleTitleClickEvent() {
    return scrollToRef(elemRef);
  };
  useEffect(function () {
    subscribe('product:title:click', handleTitleClickEvent);
    return function () {
      unsubscribe('product:title:click', handleTitleClickEvent);
    };
  });
  var brandSlug = slugify(productBrand == null ? void 0 : productBrand.label);
  var hasInvalidProps = !title || !productSku || !productBrand;
  return !hasInvalidProps && React.createElement(React.Fragment, null, React.createElement(Heading, _extends({
    "data-testid": "heading-product-title",
    as: "h1"
  }, staticProps, {
    ref: elemRef
  }), fullTitle), React.createElement(SubHeading, {
    descriptionClick: handleClick,
    productSku: productSku,
    productBrand: _extends({}, productBrand, {
      url: "".concat(basePath, "/marcas/").concat(brandSlug, "/")
    }),
    brandSpa: brandSpa,
    subHeadingStatic: subHeading
  }));
};
process.env.NODE_ENV !== "production" ? HeadingProduct.propTypes = {
  data: shape({
    product: shape({
      brand: shape({
        label: string,
        slug: string
      }),
      variationId: string
    })
  }),
  "static": shape({}),
  structure: shape({
    basePath: string
  }).isRequired
} : void 0;
HeadingProduct.defaultProps = {
  data: {},
  "static": {}
};
HeadingProduct.ssr = true;
HeadingProduct.dataSource = {
  product: {
    query: 'ProductQuery'
  }
};
export default withLayoutProps(HeadingProduct);