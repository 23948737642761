import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var _excluded = ["media"];
import React, { useCallback, useEffect, useState } from 'react';
import { bool, number, shape, string } from 'prop-types';
import Carousel from '@magalu/stereo-ui/molecules/Carousel';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Image from '@magalu/stereo-ui/atoms/ResponsiveImage';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import Hr from '@magalu/stereo-ui/atoms/HorizontalRule';
import Text from '@magalu/stereo-ui/atoms/Text';
import { Icon } from '@magalu/stereo-ui-icons';
import { getThumbsFormatted } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import ProductInfoBar from '../ProductInfoBar';
import withLayoutProps from '../../../hocs/withLayoutProps';
import useZoom from './useZoom';
import { GalleryImagesContainer, GalleryThumbButton, PodcastAudio, PodcastImage, ZoomContainer, ZoomImage } from './MediaGalleryModal.styles';
var imgHeight = 1500;
var imgWidth = 1500;
var thumbImgHeight = 74;
var thumbImgWidth = 96;
var MediaGalleryModal = function MediaGalleryModal(_ref) {
  var data = _ref.data,
    modalOpen = _ref.open,
    structure = _ref.structure,
    staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var _useState = useState(modalOpen),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var _useState3 = useState(0),
    _useState4 = _slicedToArray(_useState3, 2),
    activeImage = _useState4[0],
    setActiveImage = _useState4[1];
  var _useState5 = useState({
      height: 'auto',
      width: 'auto'
    }),
    _useState6 = _slicedToArray(_useState5, 2),
    zoomContainerSize = _useState6[0],
    setZoomContainerSize = _useState6[1];
  var _useZoom = useZoom({
      height: imgHeight,
      width: imgWidth
    }),
    handleDown = _useZoom.handleDown,
    handleLeave = _useZoom.handleLeave,
    handleMove = _useZoom.handleMove,
    handleUp = _useZoom.handleUp,
    setZoom = _useZoom.setZoom,
    zoom = _useZoom.zoom,
    zoomDragging = _useZoom.zoomDragging,
    zoomPosition = _useZoom.zoomPosition;
  var thumbsPerColumn = staticProps.thumbsPerColumn;
  var _ref2 = (data == null ? void 0 : data.product) || {},
    media = _ref2.media,
    product = _objectWithoutProperties(_ref2, _excluded);
  var images = (media == null ? void 0 : media.images) || [];
  var videosDefault = (media == null ? void 0 : media.videos) || [];
  var podcastsDefault = (media == null ? void 0 : media.podcasts) || [];
  var _useState7 = useState(videosDefault),
    _useState8 = _slicedToArray(_useState7, 2),
    videos = _useState8[0],
    setVideos = _useState8[1];
  var _useState9 = useState(podcastsDefault),
    _useState10 = _slicedToArray(_useState9, 2),
    podcasts = _useState10[0],
    setPodcasts = _useState10[1];
  var thumbsFormatted = getThumbsFormatted(videos, podcasts, images, thumbsPerColumn);
  var handleClickEvent = useCallback(function (imageIndex) {
    setVideos(open ? [] : videosDefault);
    setPodcasts(open ? [] : podcastsDefault);
    setOpen(!open);
    setActiveImage(imageIndex || 0);
  }, [open]);
  useEffect(function () {
    subscribe('mediagallerymodal:toggle', handleClickEvent);
    return function () {
      unsubscribe('mediagallerymodal:toggle', handleClickEvent);
    };
  }, []);
  useEffect(function () {
    if (!open) {
      setZoom(null);
    }
  }, [open]);
  return React.createElement(React.Fragment, null, React.createElement(Dialog, {
    onClickOutside: handleClickEvent,
    height: "100%",
    bg: "background.white",
    position: "center",
    type: "dialog",
    visible: open,
    portal: true,
    showClose: true,
    title: React.createElement(ProductInfoBar, {
      data: data,
      structure: structure,
      "static": staticProps
    }),
    titleStyle: {
      alignItems: 'center',
      columnGap: 4,
      forwardedAs: Flex,
      width: 'calc(100% - 32px)'
    }
  }, React.createElement(Hr, null), React.createElement(Grid, {
    mt: 4,
    gridGap: "0 1rem",
    gridTemplateColumns: "1fr 4fr",
    height: "78%"
  }, React.createElement(Carousel, {
    auto: false,
    slidesPerView: 2
  }, thumbsFormatted.map(function (list) {
    var _list$;
    return React.createElement(Flex, {
      key: (_list$ = list[0]) == null ? void 0 : _list$.id,
      role: "tablist",
      flexDirection: "column",
      flexWrap: "wrap",
      paddingTop: "0.5rem",
      alignItems: list.length <= thumbsPerColumn ? 'center' : 'initial'
    }, list.map(function (_ref3) {
      var id = _ref3.id,
        type = _ref3.type,
        link = _ref3.link;
      if (type === 'video') {
        return React.createElement(GalleryThumbButton, {
          active: activeImage === id,
          "aria-label": "Selecionar V\xEDdeo",
          "aria-selected": activeImage === id,
          key: "thumb-".concat(link),
          onClick: function onClick() {
            return setActiveImage(id);
          },
          isImage: false
        }, React.createElement(Icon, {
          name: "VideoCircle"
        }), React.createElement(Text, {
          color: "luContentLink"
        }, "V\xEDdeo"));
      }
      if (type === 'podcast') {
        return React.createElement(GalleryThumbButton, {
          active: activeImage === id,
          "aria-label": "Selecionar Dica em \xE1udio",
          "aria-selected": activeImage === id,
          key: "thumb-".concat(link),
          onClick: function onClick() {
            return setActiveImage(id);
          },
          isImage: false
        }, React.createElement(Icon, {
          name: "PodcastCircle"
        }), React.createElement(Text, {
          color: "luContentLink"
        }, "Dica"));
      }
      if (type === 'image') {
        return React.createElement(GalleryThumbButton, {
          active: activeImage === id,
          "aria-selected": activeImage === id,
          "aria-label": "Selecionar imagem",
          key: "thumb-".concat(link),
          onClick: function onClick() {
            return setActiveImage(id);
          },
          isImage: true
        }, React.createElement(Image, {
          alt: "Imagem miniatura de ".concat(product.title),
          height: thumbImgHeight,
          src: link,
          width: thumbImgWidth
        }));
      }
      return null;
    }));
  })), React.createElement(GalleryImagesContainer, null, React.createElement(Carousel, {
    auto: false,
    goTo: activeImage,
    lazy: true,
    showArrowControls: true,
    showControls: false,
    onChange: setActiveImage
  }, videos.map(function (video) {
    var videoSrc = video.replace('http://', '//').replace('/v/', '/embed/');
    return React.createElement(Flex, {
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      key: "gallery-".concat(video)
    }, React.createElement("iframe", {
      allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      allowFullScreen: true,
      frameBorder: "0",
      height: "100%",
      src: videoSrc,
      title: "YouTube video player",
      width: "95%"
    }));
  }), podcasts.map(function (podcast) {
    return React.createElement(Flex, {
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      key: "gallery-".concat(podcast)
    }, React.createElement(PodcastImage, {
      alt: "Imagem da Lu",
      src: "https://wx.mlcdn.com.br/site/shared/lu-content/lu-podcast.png",
      title: "Imagem da Lu"
    }), React.createElement(Text, {
      color: "scratched",
      frontSize: 0
    }, "Clique abaixo para ouvir a dica"), React.createElement(PodcastAudio, {
      controls: true,
      preload: true
    }, React.createElement("source", {
      src: podcast,
      type: "audio/mpeg"
    }), React.createElement("caption", {
      style: {
        display: 'none'
      }
    }, product == null ? void 0 : product.title)));
  }), images.map(function (image, idx) {
    var isZoomActive = zoom === idx;
    return React.createElement(ZoomContainer, {
      "data-testid": "zoom-container",
      key: "gallery-".concat(image),
      style: _extends({}, zoomContainerSize)
    }, React.createElement(ZoomImage, {
      activeZoom: isZoomActive,
      alt: "Imagem de ".concat(product.title),
      draggable: false,
      height: imgHeight,
      onLoad: function onLoad(e) {
        var _e$target = e.target,
          height = _e$target.offsetHeight,
          width = _e$target.offsetWidth;
        setZoomContainerSize({
          height: height,
          width: width
        });
      },
      onMouseMove: handleMove,
      onMouseDown: function onMouseDown(e) {
        return handleDown(e, isZoomActive);
      },
      onMouseUp: function onMouseUp() {
        return handleUp(isZoomActive, idx);
      },
      onMouseLeave: handleLeave,
      src: image,
      width: imgWidth,
      style: _extends({}, isZoomActive ? {
        transform: "translate(".concat(zoomPosition.clientX, "px, ").concat(zoomPosition.clientY, "px)")
      } : {}, zoomDragging ? {
        cursor: 'grabbing'
      } : {})
    }));
  }))))));
};
process.env.NODE_ENV !== "production" ? MediaGalleryModal.propTypes = {
  data: shape({
    product: shape({
      media: shape({}),
      mediaGalleryAlt: string,
      reference: string,
      title: string
    })
  }),
  open: bool,
  "static": {
    thumbsPerColumn: number
  },
  structure: shape({
    config: shape({
      cartUrl: shape({
        guarantee: string
      })
    })
  })
} : void 0;
MediaGalleryModal.defaultProps = {
  data: {},
  open: false,
  "static": {
    thumbsPerColumn: 5
  },
  structure: {
    config: {
      cartUrl: {
        guarantee: ''
      }
    }
  }
};
MediaGalleryModal.ssr = false;
MediaGalleryModal.displayName = 'MediaGalleryModal';
export default withLayoutProps(MediaGalleryModal);